import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import ReactGA from 'react-ga';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';


import new_image from '../assets/images/news-page-image.png';
import youtube from '../assets/images/youtube.png';

import calendar from '../assets/svg/calendar-blog-detail.svg';
import spotify from '../assets/svg/blog-detail1.svg';
import instagram from '../assets/svg/blog-detail2.svg';
import facebook from '../assets/svg/blog-detail3.svg';

import Slider from "react-slick";
import BlogArticle from '../components/blogArticle';
import Newsletter from '../components/newsletter';
import ReactPaginate from 'react-paginate';

var striptags = require('striptags');



class BlogDetailPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }


    }



    render() {

        return (
            <div className="page-wrap">
                {/* {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null

                } */}
                <section>
                    <Container fluid>
                        <Row className="news-section">
                            <Col lg="12">
                                {
                                    this.state.data ?
                                        <div className="news-page">


                                            <div className="news-page-image overlay">
                                                <img src={Object.get(this.state.data, 'image')} />
                                            </div>
                                            <div className="news-page-content">
                                                <h3>{Object.translate(this.state.data, 'title', this.props.lang)}</h3>
                                                {/* <div className="content" dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'content', this.props.lang) }}></div> */}
                                                {
                                                    this.state.data && this.state.data.content && this.state.data.content.map((item, idx) => {
                                                        if (item.type == 'image') {
                                                            return (
                                                                <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section no-padding-col">
                                                                    <img src={item.value} />
                                                                </Col>
                                                            )
                                                        } else {
                                                            return (
                                                                <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section no-padding-col" dangerouslySetInnerHTML={{ __html: item.value && item.value[this.props.lang] }}>
                                                                </Col>
                                                            )
                                                        }
                                                    })
                                                }
                                                {/* <div className="player">
                                                    <img src={youtube} />
                                                </div> */}

                                                {/* <div className="performances">
                                                    <div className="table-title">
                                                        <h5>Performances</h5>
                                                    </div>
                                                    <div className="table-row">
                                                        <Isvg src={calendar} />
                                                        <div className="row-info">
                                                            <Col lg="4" className="col">
                                                                <h3>Name</h3>
                                                            </Col>
                                                            <Col lg="4" className="col">
                                                                <h3>Sat, May 11</h3>
                                                            </Col>
                                                            <Col lg="4" className="col">
                                                                <h3>Gross Field, England</h3>
                                                            </Col>
                                                        </div>
                                                    </div>

                                                    <div className="table-row">
                                                        <Isvg src={calendar} />
                                                        <div className="row-info">
                                                            <Col lg="4" className="col">
                                                                <h3>Name</h3>
                                                            </Col>
                                                            <Col lg="4" className="col">
                                                                <h3>Sun, May 26</h3>
                                                            </Col>
                                                            <Col lg="4" className="col">
                                                                <h3>Wellington, New Zealand</h3>
                                                            </Col>
                                                        </div>
                                                    </div>
                                                    <div className="table-row">
                                                        <Isvg src={calendar} />
                                                        <div className="row-info">
                                                            <Col lg="4" className="col">
                                                                <h3>Name</h3>
                                                            </Col>
                                                            <Col lg="4" className="col">
                                                                <h3>Sat, April 02</h3>
                                                            </Col>
                                                            <Col lg="4" className="col">
                                                                <h3>Adam Lane, Peru </h3>
                                                            </Col>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="news-social-networks">
                                                {
                                                    Object.translate(this.state.data, 'buttonLink1', this.props.lang) ?
                                                        <a href={Object.translate(this.state.data, 'buttonLink1', this.props.lang)} target="_blank"><Isvg src={spotify} /></a> : null
                                                }
                                                {
                                                    Object.translate(this.state.data, 'buttonLink2', this.props.lang) ?
                                                        <a href={Object.translate(this.state.data, 'buttonLink2', this.props.lang)} target="_blank"><Isvg src={instagram} /></a> : null
                                                }
                                                {
                                                    Object.translate(this.state.data, 'buttonLink3', this.props.lang) ?
                                                        <a href={Object.translate(this.state.data, 'buttonLink3', this.props.lang)} target="_blank"><Isvg src={facebook} /></a> : null
                                                }



                                            </div>
                                        </div>
                                        : null
                                }
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

export default Page(BlogDetailPage);