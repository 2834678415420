

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Routes from './routes'
import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { langs } from './langs';
import { withRouter } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import ReactGA from 'react-ga';
import socketIO from 'socket.io-client';

// ReactGA.initialize('Your Unique ID');




const rootReducer = combineReducers({
  form: formReducer

});

const store = createStore(rootReducer)



function generateAlias(str) {
  str = str.toLowerCase();
  str = str.replace(/ä/g, 'a');
  str = str.replace(/ö/g, 'o');
  str = str.replace(/ü/g, 'u');
  str = str.replace(/ß/g, 'b');

  str = str.replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase()
  str = str.replace(/-+/g, '-');

  return str;
}

if (String.prototype.generateAlias == null) {
  String.prototype.generateAlias = function () {
    return generateAlias(this);
  }
}


Object.translate = function (o, s, lang) {
  if (!o) {
    return '';
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o[lang] ? o[lang] : o['ba'];
}


Object.get = function (o, s) {
  console.log(o, s)
  if (!o) {
    return null;
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}


if (String.prototype.translate == null) {
  String.prototype.translate = function (lang) {

    /* if (!localStorage.translate){
       localStorage.translate = JSON.stringify({
         'ba': {
  
         },
         'en': {
  
         }
       });
     }
  
     let obj = JSON.parse(localStorage.translate);
     obj.en[this] = this;
     obj.ba[this] = this;
 
     localStorage.translate = JSON.stringify(obj);
     
     return this;*/

    if (langs[lang] && langs[lang][this])
      return langs[lang][this];
    else return this;
  }
}



class App extends Component {
  constructor(props) {
    super(props);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    this.translate = this.translate.bind(this);
    this.setLang = this.setLang.bind(this);
    this.setLightMode = this.setLightMode.bind(this);


    let lang = 'ba';
    if (typeof window !== 'undefined') {

      window.googleMapsCallback = this.googleMapsCallback;

      if (props.location.pathname.indexOf('/en') !== -1) {
        lang = 'en';
      }


    } else {
      lang = this.props.lang;
    }
    this.state = {
      _googleMapsLoaded: false,
      lang: lang,
      lightMode: 0,
      services: [],
      latestNews: [],
      cookie: {
        _gid: true,
        _ga: true,
        VISITOR_INFO1_LIVE: true,
        CONSENT: true,
        YSC: true,
        test_cookie: true,
      },

      page: 0,
      verificationDone: false,
      activeYear: new Date(Math.floor(new Date().getTime())).getFullYear(),
      activeLink: null,
      ...props.appInitialData,


    };

  }

  setCookie = (cookie) => {
    this.setState({ cookie })
  }

  setLang(lang) {
    this.setState({
      lang: lang
    });
  }
   setActiveLink = (link) => {
     this.setState({
       activeLink: link
     }, () => { ReactGA.pageview(window.location.pathname + window.location.search);});
   }
  setLightMode(val) {
    this.setState({
      lightMode: val
    });
  }

  setPage = (page) => {
    this.setState({ page });
  }
  setPageTotal = (pageTotal) => {
    this.setState({ pageTotal });
  }




  translate(text) {
    return text;
  }


  render() {
    let meta;

    if (this.state.metaTags) {
      meta = {
        title: this.state.metaTags.title && this.state.metaTags.title[this.state.lang],
        description: this.state.metaTags.description && this.state.metaTags.description[this.state.lang],
        //canonical: 'http://example.com/path/to/page',
        meta: {
          charset: 'utf-8',
          name: {
            'og:url': this.state.metaTags['og:url'] && this.state.metaTags['og:url'][this.state.lang],
            'og:title': this.state.metaTags['og:title'] && this.state.metaTags['og:title'][this.state.lang],
            'og:image': this.state.metaTags['og:image'] && this.state.metaTags['og:image'][this.state.lang],
            'og:description': this.state.metaTags['og:description'] && this.state.metaTags['og:description'][this.state.lang],
            'og:type': this.state.metaTags['og:type'] && this.state.metaTags['og:type'][this.state.lang],
          }
        }
      };
    }
    if (!this.state.verificationDone) {
      return null;
    }
    return (
      <Provider store={store}>
        {this.state.metaTags ? <DocumentMeta {...meta} /> : null}
        <Routes
          {...this.state}
          translate={this.translate}
          setLang={this.setLang}
          setLightMode={this.setLightMode}
          serverFetch={this.props.serverFetch}
          initialData={this.props.initialData ? this.props.initialData : {}}
          increment={this.increment}
          decrement={this.decrement}
          setPage={this.setPage}
          setPageTotal={this.setPageTotal}
          setCookie={this.setCookie}
          setActiveLink = {this.setActiveLink}
          allowCookies={() => {
            localStorage.allowCookies = true;
            if(this.state.cookie){
              localStorage._gid = this.state.cookie._gid;
              localStorage._ga = this.state.cookie._ga;
              localStorage.VISITOR_INFO1_LIVE = this.state.cookie.VISITOR_INFO1_LIVE;
              localStorage.CONSENT = this.state.cookie.CONSENT;
              localStorage.YSC = this.state.cookie.YSC;
              localStorage.test_cookie = this.state.cookie.test_cookie;
              localStorage.cookieTs = Math.floor(new Date().getTime()/1000)
            }
           
            this.setState({
              cookies: true
            });
          }}
          disallowCookies={() => {
            localStorage.allowCookies = false;
            this.setState({
              cookies: true
            });
          }}
        />
      </Provider>

    );

  }





  componentDidMount() {
    if (localStorage.token) {
      fetch('https://api.freedommusic.rs/user', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      }).then(res => {
        return res.json()
      }).then((result) => {
        if (!result.error) {

          this.setState({
            uData: result,
            verificationDone: true
          })
        }
        else {
          localStorage.removeItem('token');
          this.setState({ verificationDone: true });
        }
      })
      fetch('https://www.instagram.com/freedommusicsrbija/', {
        headers: { 'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.80 Safari/537.36' }
      }).then((res) => res.text()).then((source) => {
        try {
          var json = JSON.parse(source.split('window._sharedData =')[1].split(';</script>')[0]);
          let edges = json.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges;
          console.log(edges)
          let instagramPhotos = edges.map((item) => {
            return {
              preview: item.node.thumbnail_resources[1].src,
              link: 'https://instagram.com/p/' + item.node.shortcode
            }
          });
          this.setState({
            instagramPhotos: instagramPhotos.slice(0, 5)
          })
        } catch (e) {

        }
      })
    } else {
      this.setState({ verificationDone: true });
    }


    if (localStorage.allowCookies) {
      this.setState({
        cookies: true
      });
    }

    ReactGA.initialize('UA-189010797-1');

    this.props.history.listen((location, action) => {
      let lang = 'ba';
      if (location.pathname.indexOf('/en') !== -1) {
        lang = 'en';
      } else if (location.pathname.indexOf('/sr') !== -1) {
        lang = 'sr';
      }

      if (this.state.lang != lang) {
        this.setState({ lang: lang }, () => {
          fetch('https://api.freedommusic.rs/seo/' + this.state.lang, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ url: location.pathname })
          }).then((res) => res.json()).then((result) => {

            this.setState({
              metaTags: result
            })
          });

        });
      } else {
        fetch('https://api.freedommusic.rs/seo/' + this.state.lang, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ url: location.pathname })
        }).then((res) => res.json()).then((result) => {

          this.setState({
            metaTags: result
          })
        });

      }


      console.log(window.location.pathname);
      //ReactGA.pageview(window.location.pathname + window.location.search);
      // location is an object like window.location

      //console.log(action, location.pathname, location.state)





    });




    fetch('https://api.freedommusic.rs/services/0', {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    }).then(res => res.json()).then((services0) => {
      this.setState({
        services0
      })
    })
    fetch('https://api.freedommusic.rs/services/1', {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    }).then(res => res.json()).then((services1) => {
      this.setState({
        services1
      })
    })

  }

  increment = () => {
    this.setState({ activeYear: this.state.activeYear + 1 })
  }
  decrement = () => {
    this.setState({ activeYear: this.state.activeYear - 1 })
  }


  googleMapsCallback() {
    console.log("true");
    this.setState({ _googleMapsLoaded: true });
  }

}

export default withRouter(App);
