import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Isvg from 'react-inlinesvg';

import red_right_chevron from '../assets/svg/red-right-chevron.svg';
import new_image from '../assets/images/new-image.png';
import calendar from '../assets/svg/calendar-icon.svg';

export class BlogArticle extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        console.log(this.props.active);
        return (
            // <Link to={`/novosti/${Object.translate(this.props, 'alias', this.props.lang)}`}>
            <>
                {
                    this.props && this.props.active ?
                        <div className="news-field active-border">
                            <div className="news-image overlay active-border-image">
                                <img src={this.props.image}/>
                            </div>

                            <div className="news-info active-padding">
                                <h3>Lorem Ipsum</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                    ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                </p>
                                <div className="news-more">
                                    <button className="clean-btn"><Isvg src={red_right_chevron} /></button>
                                    <h4>CLICK FOR MORE</h4>
                                </div>
                            </div>

                            <div className="news-date">
                                <h6>11.08.2020</h6>
                                <Isvg src={calendar} />
                            </div>
                        </div>
                        :
                        <div className="news-field">
                            <div className="news-image overlay">
                                <img src={this.props.image} />
                            </div>

                            <div className="news-info not-active-padding">
                                <h3>Lorem Ipsum</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                    ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                        </p>
                            </div>

                            <div className="news-date">
                                <h6>11.08.2020</h6>
                                <Isvg src={calendar} />
                            </div>
                        </div>
                }
            </>

            // </Link>
        )
    }
}

export default BlogArticle;