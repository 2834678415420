import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,

} from 'reactstrap';



import logo from '../assets/svg/logo.svg';
import fb_icon from '../assets/svg/facebook-icon.svg';
import instagram_icon from '../assets/svg/instagram-icon.svg';

import phone_icon from '../assets/svg/phone-ico.svg';
import mail_icon from '../assets/svg/mail-ico.svg';
import location_icon from '../assets/svg/location-ico.svg';


export class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        return (
            <footer className="footer">
                <Container>

                    <Row>
                        <Col md="3">
                            <div className="logo">
                                <img src={logo} />
                            </div>
                            <div className="description">
                                <p>Lorem ipsum dolor sit amet,
consetetur sadipscing elitr, sed diam
nonumy eirmod tempor...
</p>
                            </div>

                            <div className="social">
                                <p>Pratite nas

                                                                            <a href="https://www.facebook.com/" target="_blank"><Isvg src={fb_icon} /></a>
                                    <a href="https://www.instagram.com/" target="_blank"><Isvg src={instagram_icon} /></a>


                                </p>
                            </div>
                        </Col>
                        <Col md={{ size: 4, offset: 1 }}>
                            <div className="contact">
                                <Isvg src={phone_icon} /> POZOVITE NAS
                                    <p>+387 33 821 494

                                    </p>
                            </div>
                            <div className="nav">
                                <h6>NAVIGACIJA</h6>
                                <ul>

                                    <li><Link to='/'>Početna</Link></li>
                                    <li><Link to='/category'>Proizvodi</Link></li>
                                    <li><Link to='/page/o-nama' >O nama</Link></li>
                                    <li><Link to='/novosti'>Novosti</Link></li>
                                    <li><Link to='/page/servis'>Servis</Link></li>
                                    <li><Link to='/contact'>Kontakt</Link></li>





                                </ul>
                            </div>

                        </Col>
                        <Col md="4">
                            <div className="contact location">
                                <Isvg src={location_icon} /> LOKACIJA
                                    <p>Vladimira Nazora 5<br/>
71000 Sarajevo<br /></p>
                            </div>
                            <h6>RADNO VRIJEME</h6>
                            <h6 className="working-time-days">PON-PET</h6>
                            <p>10.00 - 22.00</p>
                            <h6 className="working-time-days">SUB</h6>
                            <p>10.00 - 22.00</p>

                        </Col>

                        <Col md="4">
                            {/* <div className="contact d-none d-sm-block">
                                <i className="mdi mdi-map-marker" /> LOKACIJA
                                <p>Jovana Dučića 25a, 78000<br />
                                    Banja Luka</p>
                            </div>

                            <h6>RADNO VRIJEME</h6>
                            <h6>PON-PET</h6>
                            <p>10.00 - 22.00</p>
                            <h6>SUB-NED</h6>
                            <p>10.00 - 22.00</p>

    */}
                        </Col>

                        <div className="spacer"></div>

                        <div className="copyright">
                            <p>Copyright © AUTO PROJEKT  - 2020. All Rights Reserved.</p>
                            <p>Created by <span><a href="https://www.novamedia.agency">nova media.</a></span></p>
                        </div>

                    </Row>
                </Container>

            </footer>
        )
    }
}

export default Footer;