import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';


import red_right_chevron from '../assets/svg/red-right-chevron.svg';
import right_chevron from '../assets/svg/right-chevron.svg';
import music from '../assets/images/music.jpg';

import close_ico from '../assets/svg/close-ico.svg';
import lightbox_dots from '../assets/svg/lightbox-dots.svg';


import new_image from '../assets/images/news-page-image.png';
import youtube from '../assets/images/youtube.png';

import calendar from '../assets/svg/calendar-blog-detail.svg';
import spotify from '../assets/svg/blog-detail1.svg';
import instagram from '../assets/svg/blog-detail2.svg';
import facebook from '../assets/svg/blog-detail3.svg';

import Slider from "react-slick";
import BlogArticle from '../components/blogArticle';
import Newsletter from '../components/newsletter';
import ReactPaginate from 'react-paginate';
import ReactGA from 'react-ga';
var striptags = require('striptags');



class CookiesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,

        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);


    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }
    }



    render() {

        return (
            <div className="page-wrap">
                {/* {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null

                } */}
                <div className='cookies-page'>
                    <h3>Kolačići</h3>
                    <h4>Kako koristimo kolačiće (Cookies)</h4>
                    <p>
                        Sa ciljem da svakom korisniku koji posećuje FreedomMusic veb stranice pružimo lako i neometano kretanje kroz stranice, ovaj sajt koristi kolačiće, odnosno, neznatnu količinu informacija koju freedommusic.rs šalje Vašem računaru. U skladu sa Zakonom o zaštiti podataka o ličnosti, u obavezi smo da, pre slanja kolačića, dobijemo Vaš pristanak te Vas zbog toga obaveštavamo o značaju kolačića za Vaše korisničko iskustvo.
                    </p>
                    <h4>Šta su kolačići?</h4>
                    <p>
                        Kolačići (eng. Cookies) predstavljaju vrstu informacija koja se šalje Vašem računaru od strane internet stranice koju ste posetili. Da bi stranice mogle da šalju te informacije Vašem računaru, one prethodno čuvaju podatke o korišćenju date veb stranice. Najčešće informacije koje kolačići čuvaju, a tiču se Vas, kako korisnika, jesu jezik na kom pretražujete usluge i IP adresa. Upravo zbog ovih kolačića, sledeći put kad otvorite istu veb stranicu, sadržaj koji se prikazuje će se lakše i brže učitavati.
                        Iako kolačići imaju tu sposobnost da čuvaju veliki broj informacija, oni nisu u stanju da pristupe Vašim ličnim podacima, odnosno, podacima za koje im Vi niste dali pristup. Dakle, ne postoji mogućnost da kolačići pristupe datotekama na Vašem računaru, Vašoj e-mail adresi, imenu ili bilo čemu drugom, ukoliko Vi niste sami pružili te informacije.

                    </p>
                    <h4>Koje kolačiće koristi Freedommusic.rs i u koje svrhe?</h4>
                    <p>
                        Freedommusic.rs koristi privremene kolačiće.
                        Privremeni kolačići (eng. Session Cookies) su oni kolačići koji nestaju nakon što zatvorite sve prozore Vašeg pretraživača. Privremene kolačiće koristimo kako bismo Vam omogućili da pristupite sadržaju internet stranica i lakše pregledate sadržaj

                    </p>
                    <h4>Šta se može dogoditi ukoliko ne pristanete na uslove korišćenja kolačića?</h4>
                    <p>
                        Ukoliko se ne slažete sa uslovima korišćenja kolačića, odnosno, ukoliko ne pritisnete dugme “Prihvatam” prilikom posete Freedommusic.rs veb-sajtu, neke od funkcija interneta stranica će verovatno imati poteškoće sa radom te neće vršiti performanse onako kako ste očekivali. Ovo samo znači da ćete moći da pregledate stranice ali da postoji mogućnost sa određene opcije neće biti dostupne.
                        

                    </p>
                    <h4>Saznajte više o kolačićima</h4>
                    <p>
                        Ukoliko želite da saznate više o kolačićima i tome kako možete njima upravljati i brisati ih, kliknite na opciju Pomoć (eng. Help) koja se nalazi u padajućem meniju pretraživača koji koristite.
                    </p>
                </div>

            </div>
        );
    }
}

export default Page(CookiesPage);