
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';



class Checkboxx extends Component {
    constructor(props) {
        super(props);

        this.state = {
            
        };
    }






    render() {
        //console.log(this.props)
        //console.log(generateAlias("ćčććasd"))
        return (
            <>
                {this.props.label ? <label className={this.props.error ? "label" : "label"}>{this.props.label}</label> : null}
                {/* <input className={this.props.error ? "input-field required" : "input-field"} type={this.props.type ? this.props.type : 'text'} onChange={this.props.onChange} value={this.props.value} placeholder={this.props.placeholder} /> */}
                <div className="check-option">
                    <button type="button" className={this.props.value == 'male' ? "check-button-active check-distance" : "check-button check-distance"} onClick={() => { this.props.onChange('male')}}>{'Musko'.translate(this.props.lang)}</button>
                    <button type="button" className={this.props.value == 'female' ? "check-button-active" : "check-button"} onClick={() => { this.props.onChange('female')}}>{'Zensko'.translate(this.props.lang)}</button>
                </div>
            </>
        );
        //{this.props.value == 'male' ? "check-button check-distance" : null}
    }
}

export default Checkboxx;