import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import ReactGA from 'react-ga';
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import Form from '../components/forms/resetPasswordForm';

import back_icon from '../assets/svg/back.svg';

var striptags = require('striptags');

class RegisterPage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {

        };
    }

    componentDidMount() {

        // if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        // for (let i = 0; i < this.props.loadData.length; i++) {
        //     this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
        //         this.setState({
        //             ...data
        //         })
        //     })
        // }
        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }



    submit(data) {
        console.log(data);

        fetch(`https://api.freedommusic.rs/user/send/reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error
                })
            } else {
                // localStorage.setItem('token', result.token);
                // this.props.verifyUser();
                this.props[0].history.push('/')
            }
        })

     

    }



    render() {



        return (
            <div className="">

                <Container fluid>

                    <Row>
                        <div className="login-wrap">
                            <div className="login-page">
                                <Col lg="12">
                                    <div className="login">
                                        <h1>RESET PASSWORD</h1>
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <Form onSubmit={this.submit} />
                                </Col>
                            </div>
                        </div>
                    </Row>

                </Container>

            </div>
        );
    }
}

export default Page(RegisterPage);