import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import ContactForm from '../components/forms/homeContactForm';


import Image from '../components/image';

import rightChevron from '../assets/svg/right-chevron.svg';
import downChevron from '../assets/svg/down-chevron.svg';

import homeImage from '../assets/svg/anim.svg';

import service1 from '../assets/svg/service1.svg';
import service2 from '../assets/svg/service2.svg';
import service3 from '../assets/svg/service3.svg';
import service4 from '../assets/svg/service4.svg';
import serviceDots from '../assets/svg/service-dots.svg';
import Map from '../components/map';

import moment from 'moment';

import truck from '../assets/images/truck.png';
import aboutusImage from '../assets/images/about-us.png';
import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import contactPhone from '../assets/svg/contact-phone.svg';
import contactMobile from '../assets/svg/contact-mobile.svg';
import contactFax from '../assets/svg/contact-fax.svg';
import contactMail from '../assets/svg/contact-mail.svg';

import contactOverlay from '../assets/images/contact-overlay.png';

import Slider from "react-slick";
import BlogArticle from '../components/blogArticle';
import Newsletter from '../components/newsletter';
import ReactGA from 'react-ga';

var striptags = require('striptags');

class AboutUsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }


        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);



    }

    componentDidUpdate(prevProps, prevState) {
    }


    render() {


        console.log(Object.get(this.state, 'section[0].image'))
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: typeof window !== 'undefined' && window.innerWidth < 768 ? 1 : 3,
            slidesToScroll: 1
        };

        return (
            <div>
                <section className="page-into">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>O nama</h1>
                            </Col>
                            <Col lg="6">
                                <ul>
                                    <li><Link to='/'>Početna</Link></li>
                                    <li><Link to='/'>O nama</Link></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="content-section">
                    <Container>
                        <Row>
                        {
                                this.state.data && this.state.data.content && this.state.data.content.map((item, idx) => {
                                    if (item.type == 'image') {
                                        return (
                                            <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section">
                                                <img src={item.value} />
                                            </Col>
                                        )
                                    } else {
                                        return (
                                            <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section" dangerouslySetInnerHTML={{ __html: item.value && item.value[this.props.lang] }}>
                                            </Col>
                                        )
                                    }
                                })
                            }

                        </Row>
                    </Container>
                </section>

                <section className="services-section">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h6>ŠTA NUDIMO</h6>
                                <h3>Naše usluge</h3>

                            </Col>
                            <Col lg="12" className="slider-container">
                                <Slider {...settings}>
                                {
                                        this.props.services1 && this.props.services1.map((item, idx) => {
                                            return (
                                                <article>
                                                    <Link to={`/usluge/${Object.translate(item, 'alias', this.props.lang)}`}>   <img src={item.image} /> </Link>
                                                    <div>
                                                        <Link to={`/usluge/${Object.translate(item, 'alias', this.props.lang)}`}>        <h6>{Object.translate(item, 'name', this.props.lang)}</h6> </Link>
                                                        <p>{Object.translate(item, 'shortDescription', this.props.lang)}</p>
                                                    </div>
                                                </article>

                                            )
                                        })
                                    }

                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <Newsletter socketIOClient={this.props.socketIOClient}></Newsletter>

            </div>
        );
    }
}

export default Page(AboutUsPage);