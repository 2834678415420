import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import ReactGA from 'react-ga';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import Form from '../components/forms/registerForm';

import back_icon from '../assets/svg/back.svg';

var striptags = require('striptags');

class AccountVerify extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {

        };
    }

    componentDidMount() {

        fetch('https://api.freedommusic.rs/user/email/verify/' + this.props[0].match.params.id + '/' + this.props[0].match.params.emailVerificationCode, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => res.json()).then((result) => {
            if (!result.error) {
                this.props[0].history.push('/prijava');
            } else {
                this.setState({
                    error: result.error
                })
            }
        })
        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }



    submit() {

    }



    render() {



        return (
            <div className="">

                <Container fluid>

                    <Row>
                        <Col lg="12">
                        </Col>
                    </Row>

                </Container>

            </div>
        );
    }
}

export default Page(AccountVerify);