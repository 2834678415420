import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import ReactGA from 'react-ga';
import red_right_chevron from '../assets/svg/red-right-chevron.svg';
import right_chevron from '../assets/svg/right-chevron.svg';
import music from '../assets/images/music.jpg';

import close_ico from '../assets/svg/close-ico.svg';
import lightbox_dots from '../assets/svg/lightbox-dots.svg';


import new_image from '../assets/images/news-page-image.png';
import youtube from '../assets/images/youtube.png';

import calendar from '../assets/svg/calendar-blog-detail.svg';
import spotify from '../assets/svg/blog-detail1.svg';
import instagram from '../assets/svg/blog-detail2.svg';
import facebook from '../assets/svg/blog-detail3.svg';

import Slider from "react-slick";
import BlogArticle from '../components/blogArticle';
import Newsletter from '../components/newsletter';
import ReactPaginate from 'react-paginate';

var striptags = require('striptags');



class GalleryDetailPage extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.state = {
            ...props.initialData,
            images: [],
            activeIndex: 0
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);

    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }
    }
    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.images.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.images.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }
    
  
    render() {
        const { activeIndex } = this.state;
        const slides = this.state.images && this.state.images.map((item) => {
            return (
                <CarouselItem
                    tag="div"
                    key={item}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                >
                    <div className="lightbox-item">
                        <div className="lightbox-item-title">
                            <h3>{Object.translate(this.state.gallery, 'name', this.props.lang)}</h3>
                            <div className="close" onClick={() => this.setState({ lightbox: null })}>
                                <Isvg src={close_ico} />
                            </div>
                        </div>
                        <img src={item} />
                    </div>

                </CarouselItem>
            );
        });
        
        return (
            <div className="page-wrap">
                {/* {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null

                } */}
                <section>
                    <Container fluid >
                        <Row className="performer gallery">
                           
                            <Col lg="12">

                                <div className="performer-image overlay2">
                                    <img src={Object.get(this.state.gallery, 'images[0]')} />
                                </div>
                                <div className="performer-info">
                                    <h1 className="marginb42">{Object.translate(this.state.gallery, 'name', this.props.lang)}</h1>
                                    <Col lg="6" className="no-padding-col">
                                        <p className="marginb42">{Object.translate(this.state.gallery, 'description', this.props.lang)}</p>
                                    </Col>
                                    

                                    <div className="performer-social-networks">
                                        {
                                            Object.get(this.state.gallery, 'buttonLink1') && Object.get(this.state.gallery, 'buttonLink1') != null ?
                                            
                                                Object.translate(this.state.gallery, 'buttonLink1', this.props.lang) ?
                                                    <a href={Object.translate(this.state.gallery, 'buttonLink1', this.props.lang)} target="_blank"><Isvg src={instagram} /></a> : null
                                            :
                                            null
                                        }

                                        {
                                            Object.get(this.state.gallery, 'buttonLink2') && Object.get(this.state.gallery, 'buttonLink2') != null ?
                                            
                                                Object.translate(this.state.gallery, 'buttonLink2', this.props.lang) ?
                                                    <a href={Object.translate(this.state.gallery, 'buttonLink2', this.props.lang)} target="_blank"><Isvg src={facebook} /></a> : null
                                            :
                                            null
                                        }
                                        
                                        {/* {
                                            Object.translate(this.state.gallery, 'buttonLink2', this.props.lang) ?
                                                <a href={Object.translate(this.state.gallery, 'buttonLink2', this.props.lang)} target="_blank"><Isvg src={facebook} /></a> : null
                                        } */}
                                        {/* <a href={Object.translate(this.state.gallery, 'buttonLink1', this.props.lang)}><Isvg src={instagram} /></a>
                                        <a href={Object.translate(this.state.gallery, 'buttonLink2', this.props.lang)}><Isvg src={facebook} /></a> */}
                                    </div>
                                </div>
                            </Col>
                            <Row className="gallery-images">
                                {
                                    this.state.images && this.state.images.map((item, idx) => {
                                        return (
                                            <Col lg="6">
                                                <div className="gallery-image-wrap">
                                                    <div className="gallery-image active">
                                                        <img src={item}
                                                            onClick={() => {
                                                                let index = idx;
                                                                this.setState({ lightbox: true, activeIndex: index })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })
                                }


                            </Row>
                        </Row>
                    </Container>
                </section>
                {this.state.lightbox ?
                    <div className="lightbox">

                        <Carousel
                            activeIndex={activeIndex}
                            next={this.next}
                            previous={this.previous}
                            autoPlay={null}
                        >
                            <CarouselIndicators items={slides} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                            {slides}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                        </Carousel>

                    </div>
                    : null
                }
            </div>
        );
    }
}

export default Page(GalleryDetailPage);