import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';


import {
    Container,
    Row,
    Col,
    Navbar,
    NavbarBrand,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Nav, NavItem,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';

import facebook_icon from '../assets/svg/facebook-icon.svg'
import instagram_icon from '../assets/svg/instagram-icon.svg'
import phone_icon from '../assets/svg/header-phone.svg'
import mail_icon from '../assets/svg/header-mail.svg'

import hamburger_icon from '../assets/svg/hamburger-icon.svg'

import logo from '../assets/svg/logo.svg';



class HomeHeader extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.suggestions = this.suggestions.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            suggestions: [],
            search: '',
            imageErrors: {},
            width: 0, height: 0,
            showSearch: false
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


    }





    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
        document.addEventListener('mousedown', this.handleClickOutside);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on('suggestions', (data) => {

            this.setState({
                imageErrors: {},
                suggestions: data.products
            })
        });



    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.updateWindowDimensions);

        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("suggestions");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    suggestions() {
        if (this.state.search.length) {
            this.props.socketIOClient.emit("suggestions", { search: this.state.search });
        }
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                suggestions: []
            })
        }

        if (this.leftMenuRef && !this.leftMenuRef.contains(event.target)) {
            this.setState({
                leftMenu: null
            })
        }

        /*if (this.rightMenuRef && !this.rightMenuRef.contains(event.target)) {
            this.setState({
                rightMenu: null
            })
        }*/
    }


    render() {
        return (
            <header>
                <Container>
                    {/* <Row>

                        <Col lg="3" xs="10" className="logo">
                            <Link to='/'><Isvg src={logo} /></Link>
                        </Col>

                        <Col lg={{ size: 9 }} xs={{ size: 2 }} className='hamburger' onClick={() => { this.setState({ rightMenu: !this.state.rightMenu }) }}>
                            <Isvg src={hamburger_icon} />
                        </Col>


                        <Col lg="9">
                            <Row>
                                <Col lg="12" className="header-top-container">
                                    <div className="actions">
                                        <div>
                                            <Isvg src={phone_icon} />
                                            +387 33 821 494
                                        </div>

                                        <div>
                                            <Isvg src={mail_icon} />
                                            <a href="mailto:lilocosmeticsbih@gmail.com">info@autoprojekt.ba</a>
                                        </div>

                                    </div>

                                    <div className="social">
                                        <span>Pratite nas</span>
                                        <a href='https://www.facebook.com/' target="_blank"><Isvg src={facebook_icon} /></a>
                                        <a href='https://www.instagram.com/' target="_blank"><Isvg src={instagram_icon} /></a>
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <ul className="navigation">
                                        <li className={this.props[0].location.pathname == '/' ? 'active' : ''} ><Link to='/'>POČETNA</Link></li>
                                        <li className={this.props[0].location.pathname == '/o-nama' ? 'active' : ''}><Link to='/o-nama'>O NAMA</Link></li>
                                        <li className={this.props[0].location.pathname == '/page/servis' ? 'active' : ' without-hover'}>
                                            <UncontrolledDropdown>
                                                <DropdownToggle>
                                                    SERVIS
                                                 </DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        this.props.services0 && this.props.services0.map((item, idx) => {
                                                            return (
                                                                <DropdownItem><Link to={`/servis/${Object.translate(item, 'alias', this.props.lang)}`}> {Object.translate(item, 'name', this.props.lang)} </Link> </DropdownItem>
                                                            )
                                                        })
                                                    }

                                                </DropdownMenu>
                                            </UncontrolledDropdown>

                                        </li>
                                        <li className={this.props[0].location.pathname == '/page/servis' ? 'active' : ' without-hover'}>
                                            <UncontrolledDropdown>
                                                <DropdownToggle>
                                                    USLUGE
                                                 </DropdownToggle>
                                                <DropdownMenu>
                                                {
                                                        this.props.services1 && this.props.services1.map((item, idx) => {
                                                            return (
                                                                <DropdownItem><Link to={`/usluge/${Object.translate(item, 'alias', this.props.lang)}`}> {Object.translate(item, 'name', this.props.lang)} </Link> </DropdownItem>
                                                            )
                                                        })
                                                    }

                                                </DropdownMenu>
                                            </UncontrolledDropdown>

                                        </li>

                                        <li className={this.props[0].location.pathname.indexOf('/novosti') === 0 ? 'active' : ''}><Link to='/novosti'>NOVOSTI</Link></li>
                                        <li className={this.props[0].location.pathname == '/kontakt' ? 'active' : ''}><Link to='/kontakt'>KONTAKT</Link></li>
                                        <li><a href='https://shop.autoprojekt.ba'>WEB SHOP</a></li>

                                    </ul>

                                </Col>


                            </Row>
                        </Col>
                    </Row> */}
                </Container>
                {/* <div className={this.state.rightMenu ? 'mobile-right-menu mobile-right-menu-open' : 'mobile-right-menu'}>
                    <div className="content" ref={(node) => this.rightMenuRef = node}>
                        <ul className="navigation">
                            <li className={this.props[0].location.pathname == '/' ? 'active' : ''} ><Link to='/'>POČETNA</Link></li>
                            <li className={this.props[0].location.pathname == '/o-nama' ? 'active' : ''}><Link to='/o-nama'>O NAMA</Link></li>
                            <li className={this.props[0].location.pathname == '/page/servis' ? 'active' : ' without-hover'}>
                                <UncontrolledDropdown>
                                    <DropdownToggle>
                                        SERVIS
                                                 </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem><Link to='/usluge/distribucija'> Servis utovarnih rampi </Link> </DropdownItem>
                                        <DropdownItem> <Link to='/'> Servis transportnog hlađenja  </Link> </DropdownItem>
                                        <DropdownItem> <Link to='/'>Servis rashladnih sistema na putničkim vozilima  </Link> </DropdownItem>

                                    </DropdownMenu>
                                </UncontrolledDropdown>

                            </li>
                            <li className={this.props[0].location.pathname == '/page/servis' ? 'active' : ' without-hover'}>
                                <UncontrolledDropdown>
                                    <DropdownToggle>
                                        USLUGE
                                                 </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem><Link to='/'> Projektovanje i razvoj proizvoda </Link> </DropdownItem>
                                        <DropdownItem> <Link to='/'> Atest i ATP certifikati za vozila  </Link> </DropdownItem>
                                        <DropdownItem> <Link to='/'>Osiguranje vozila  </Link> </DropdownItem>

                                    </DropdownMenu>
                                </UncontrolledDropdown>

                            </li>

                            <li className={this.props[0].location.pathname.indexOf('/novosti') === 0 ? 'active' : ''}><Link to='/novosti'>NOVOSTI</Link></li>
                            <li className={this.props[0].location.pathname == '/kontakt' ? 'active' : ''}><Link to='/kontakt'>KONTAKT</Link></li>
                            <li><a href='https://shop.autoprojetk.ba'>WEB SHOP</a></li>

                        </ul>


                    </div>
                </div> */}
            </header>

        );
    }
}


export default HomeHeader;
