import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';
import logo from '../assets/svg/logo.svg';
import menu from '../assets/svg/cart-icon.svg';
import scroll from '../assets/svg/scroll.svg';
import facebook from '../assets/svg/facebook.svg';
import instagram from '../assets/svg/instagram.svg';
import youtube from '../assets/svg/youtube.svg';
// import rocket from '../assets/svg/rocket.svg';
// import mail from '../assets/svg/mail.svg';
// import settings from '../assets/svg/settings.svg';
// import exit from '../assets/svg/exit.svg';
// import edit from '../assets/svg/edit.svg';

// import home from '../assets/svg/home.svg';

class Sidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            _show: true
        };
    }

    componentDidMount(){
        
         if(this.props.activeLink == null){
             this.props.setActiveLink(this.props[0].location.pathname)
         }else if(this.props.activeLink != this.props[0].location.pathname){
             this.props.setActiveLink(this.props[0].location.pathname)
         }
        
         //ReactGA.initialize('G-TXSX33V4LW');
         //ReactGA.pageview(window.location.pathname + window.location.search);
    }


    render() {

        return (

            <div className={this.state._show ? `sidebar` : 'sidebar sidebar-hidden'}>
                <Link to="/">
                    <div className="logo">
                        <Isvg src={logo} />
                    </div>
                </Link>
                <div className="sidebar-text">
                    <h1>Freedom Music Srbija</h1>
                    <p>
                        Mesto tvoje slobode
                        </p>
                </div>
                <div className="sidebar-scroll">
                    <button className="clean-btn"><Isvg src={scroll} /></button>
                </div>
                <div className="social-networks">
                    <div className="fb">
                        <a href="https://www.facebook.com/freedommusicsrbija">
                            <Isvg src={facebook} />
                        </a>
                    </div>
                    <div className="fb">
                        <a href="https://www.instagram.com/freedommusicsrbija/">
                            <Isvg src={instagram} />
                        </a>
                    </div>
                    <div>
                        <a href="https://www.youtube.com/channel/UCIsfdb3aN7L5BWsBuWu_R8Q/">
                            <Isvg src={youtube} />
                        </a>
                    </div>
                </div>

                {/* <div className="menu" onClick={() => this.setState({ _show: !this.state._show })}>
                    <Isvg src={menu} />
                </div> */}

            </div >
        )
    }

};

export default Sidebar;
