import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import moment from 'moment';
import Image from '../components/forms/fields/image'
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import account from '../assets/svg/account-icon.svg';
import account_image from '../assets/images/account-image.png';
import logo from '../assets/svg/logo.svg';


import menu from '../assets/svg/menu-icon.svg';
import right_chevron from '../assets/svg/right-chevron.svg';
import red_right_chevron from '../assets/svg/red-right-chevron.svg';
import account_right_chevron from '../assets/svg/account-right-chevron.svg';
import edit from '../assets/svg/edit.svg';
import password from '../assets/svg/password.svg';
import left_chevron from '../assets/svg/left-chevron.svg';
import top_chevron from '../assets/svg/top-chevron.svg';
import search from '../assets/svg/search-icon.svg';
import close from '../assets/svg/close-icon.svg';
import music from '../assets/images/music.jpg';

import Text from '../components/forms/fields/text'
import ChangePasswordForm from '../components/forms/changePasswordForm';
import EditProfileForm from '../components/forms/editProfileForm';

class RightSidebar extends Component {

    constructor(props) {
        super(props);
        this.update = this.update.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.get = this.get.bind(this);
        this.logout = this.logout.bind(this);
        this.delete = this.delete.bind(this);
        this.state = {
            _show: true,
            title: '',
            activeYear: 2020
        };
    }
    delete() {

        fetch('https://api.freedommusic.rs/user/account/delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then((res) => res.json()).then((result) => {
            if (!result.error) {
                this.logout();
                this.get();
            } else {
                this.setState({
                    error: result.error
                })
            }
        })
    }
    logout() {

        localStorage.removeItem('token');
        window.location.reload(false);

    }
    updatePassword(data) {
        fetch('https://api.freedommusic.rs/user/update/password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {
            if (!result.error) {
                this.setState({ ChangePassword: !this.state.ChangePassword, AccountPreview: !this.state.AccountPreview });
                this.get();
            } else {
                this.setState({
                    error: result.error
                })
            }
        })
    }
    update(data) {
        data.date = Math.floor(data.date.getTime() / 1000);

        fetch('https://api.freedommusic.rs/user/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {
            if (!result.error) {
                this.setState({ EditProfile: !this.state.EditProfile, AccountPreview: !this.state.AccountPreview })
                this.get();
            } else {
                this.setState({
                    error: result.error
                })
            }
        })


    }
    componentDidMount() {

        this.get();

    }

    get() {

        if (typeof localStorage !== 'undefined' && !localStorage.token) {
            return;
        }

        fetch('https://api.freedommusic.rs/user', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => res.json()).then((result) => {
            result.date = new Date(result.birthday * 1000);
            result.birthday = moment.unix(result.birthday).format('DD-MM-YYYY');
            this.setState({
                user: result
            })
        })

        if (this.props[0].location.pathname == '/') {
            this.setState({ title: 'MAIN PAGE' });
        } else if (this.props[0].location.pathname == '/galerija' || this.props[0].location.pathname == '/galerija/' + this.props[0].match.params.alias) {
            this.setState({ title: 'GALLERY' });
        }
        else if (this.props[0].location.pathname == '/kalendar' || this.props[0].location.pathname == '/kalendar/' + this.props[0].match.params.alias) {
            this.setState({ title: 'CALENDAR' });
        }
        else if (this.props[0].location.pathname == '/igrice' || this.props[0].location.pathname == '/igrice/' + this.props[0].match.params.alias) {
            this.setState({ title: 'GAMES' });
        }
        else if (this.props[0].location.pathname == '/stream') {
            this.setState({ title: 'STREAMS' });
        }
        else if (this.props[0].location.pathname == '/novosti' || this.props[0].location.pathname == '/novosti/' + this.props[0].match.params.alias) {
            this.setState({ title: 'NEWS' });
        }
        else if (this.props[0].location.pathname == '/performer' || this.props[0].location.pathname == '/performer/' + this.props[0].match.params.alias) {
            this.setState({ title: 'PERFORMER' });
        }
        else if (this.props[0].location.pathname == '/video') {
            this.setState({ title: 'VIDEO GALLERY' });
        }
        else if (this.props[0].location.pathname == '/world-of-fm') {
            this.setState({ title: 'FREEDOM MUSIC WORLDWIDE' });
        }
        else if (this.props[0].location.pathname == '/people-of-fm') {
            this.setState({ title: 'FREEDOM MUSIC TRIBE' });
        } else {
            this.setState({ title: 'MAIN PAGE' });
        }

    }

    render() {
     
        return (
            <div className="right-sidebar-wrap">

                {
                    this.props[0].location.pathname != '/registracija' && this.props[0].location.pathname != '/prijava' && this.props[0].location.pathname != '/reset-password' && this.props[0].location.pathname != '/reset-password/' + this.props[0].match.params.uid + '/' + this.props[0].match.params.resetPasswordVerificationCode ?
                        <>
                            <section>
                                <Container fluid>

                                    <Row>
                                        <Col lg="12">
                                            <div className="page-title">
                                                <div>
                                                    <div className="mob-logo">
                                                        <Link to='/'>
                                                            <Isvg src={logo} />
                                                        </Link>
                                                    </div>
                                                    <h1>{this.state.title}</h1>
                                                </div>
                                                <div>
                                                    <div className="top-menu">
                                                        <h3>
                                                            {
                                                                this.state.user ?
                                                                    <>
                                                                        {this.state.user.name}
                                                                    </>
                                                                    : null
                                                            }
                                                        </h3>
                                                        <div className="account">
                                                            <button className="clean-btn" onClick={() => { this.setState({ Account: !this.state.Account, AccountPreview: !this.state.AccountPreview }) }}>
                                                                {/* {
                                                                    this.state.user ?
                                                                        <div className="account-image-menu">
                                                                            <img src={this.state.user.image} />
                                                                        </div>
                                                                        :
                                                                        <div className="account-image-menu">
                                                                            <img src={account} />
                                                                        </div>

                                                                } */}
                                                                <div className="account-image-menu">
                                                                    {/* <img src={account} /> */}
                                                                </div>

                                                            </button>
                                                        </div>
                                                        <button className="clean-btn" onClick={() => { this.setState({ Menu: !this.state.Menu }) }}><Isvg src={menu} /></button>
                                                    </div>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col lg="12">
                                            <div className="page-header">
                                                <ul>
                                                    <li><Link to="/"><h6 className={this.props[0].location.pathname == '/' ? 'active-page' : ''}>MAIN PAGE</h6></Link></li>
                                                    <li><Link to="/galerija"><h6 className={this.props[0].location.pathname == '/galerija' || this.props[0].location.pathname == '/galerija/' + this.props[0].match.params.alias ? 'active-page' : ''}>GALLERY</h6></Link></li>
                                                    <li><Link to="/kalendar"><h6 className={this.props[0].location.pathname == '/kalendar' || this.props[0].location.pathname == '/kalendar/' + this.props[0].match.params.alias ? 'active-page' : ''}>CALENDAR</h6></Link></li>
                                                    <li><Link to="/igrice"><h6 className={this.props[0].location.pathname == '/igrice' || this.props[0].location.pathname == '/igrice/' + this.props[0].match.params.alias ? 'active-page' : ''}>GAMES</h6></Link></li>
                                                    <li><Link to="/stream"><h6 className={this.props[0].location.pathname == '/stream' ? 'active-page' : ''}>STREAMS</h6></Link></li>
                                                    <li><Link to="/novosti"><h6 className={this.props[0].location.pathname == '/novosti' || this.props[0].location.pathname == '/novosti/' + this.props[0].match.params.alias ? 'active-page' : ''}>NEWS</h6></Link></li>
                                                    <li><Link to="/performer"><h6 className={this.props[0].location.pathname == '/performer' || this.props[0].location.pathname == '/performer/' + this.props[0].match.params.alias ? 'active-page' : ''}>PERFORMER</h6></Link></li>
                                                    <li><Link to="/video"><h6 className={this.props[0].location.pathname == '/video' ? 'active-page' : ''}>VIDEO GALLERY</h6></Link></li>
                                                    <li><Link to="/world-of-fm"><h6 className={this.props[0].location.pathname == '/world-of-fm' ? 'active-page' : ''}>FREEDOM MUSIC WORLDWIDE</h6></Link></li>
                                                    <li><Link to="/people-of-fm"><h6 className={this.props[0].location.pathname == '/people-of-fm' ? 'active-page' : ''}>FREEDOM MUSIC TRIBE</h6></Link></li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <div className="page-search">

                                                <div className="nav-chevrons">
                                                    {
                                                        this.props[0].location.pathname == '/kalendar' ?
                                                            <>
                                                                <div className="left-chevron">
                                                                    <button className="clean-btn" onClick={() => { this.props.decrement() }}><Isvg src={left_chevron} /></button>
                                                                </div>
                                                                <div>
                                                                    <button className="clean-btn" onClick={() => { this.props.increment() }}><Isvg src={right_chevron} /></button>
                                                                </div>
                                                            </>
                                                            : this.props[0].location.pathname == '/galerija' || this.props[0].location.pathname == '/igrice' || this.props[0].location.pathname == '/novosti' || this.props[0].location.pathname == '/performer' ?
                                                                <>
                                                                    {
                                                                        this.props.page > 0 ?
                                                                            <div className="left-chevron">
                                                                                <button className="clean-btn" onClick={() => {
                                                                                    if (this.props.page > 0)
                                                                                        this.props.setPage(this.props.page - 1)
                                                                                }}><Isvg src={left_chevron} /></button>
                                                                            </div>
                                                                            :
                                                                            <div className="left-chevron">
                                                                                <button className="clean-btn opacity"><Isvg src={left_chevron} /></button>
                                                                            </div>
                                                                    }
                                                                    {
                                                                        Math.floor(this.props.pageTotal / 9) > this.props.page ?
                                                                            <div>
                                                                                <button className="clean-btn" onClick={() => {
                                                                                    this.props.setPage(this.props.page + 1)
                                                                                }}><Isvg src={right_chevron} /></button>
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <button className="clean-btn opacity"><Isvg src={right_chevron} /></button>
                                                                            </div>
                                                                    }

                                                                </>
                                                                :
                                                                null
                                                    }

                                                    {
                                                        this.props[0].location.pathname == '/kalendar' ?
                                                            <div className="active-year">
                                                                <h2>{this.props.activeYear}</h2>
                                                            </div>
                                                            :
                                                            this.props[0].location.pathname == '/galerija' || this.props[0].location.pathname == '/igrice' || this.props[0].location.pathname == '/novosti' || this.props[0].location.pathname == '/performer' ?
                                                                <div className="active-year">
                                                                    <h2>{this.props.page + 1}</h2>
                                                                </div>
                                                                : null
                                                    }
                                                </div>


                                                {/* <div className="search-input">
                                                    <Text placeholder="Search" />
                                                    <button className="search-btn"><Isvg src={search} /></button>
                                                </div> */}
                                            </div>
                                        </Col>
                                    </Row>


                                </Container>
                            </section>

                            <section className={this.state.Menu ? "menu-open" : "menu-closed"}>
                                <Container fluid>
                                    <Row>
                                        <Col lg="12">

                                            <div className="top-menu">
                                                <h3>
                                                    {
                                                        this.state.user ?
                                                            <>
                                                                {this.state.user.name}
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </h3>
                                                {/* <div className="account">
                                                    <button className="clean-btn" onClick={() => { this.setState({ Account: !this.state.Account, AccountPreview: !this.state.AccountPreview, Menu: !this.state.Menu }) }}>
                                                        
                                                        <div className="account-image-menu">
                                                            <img src={account} />
                                                        </div>
                                                    </button>

                                                </div> */}
                                                <button className="clean-btn" onClick={() => { this.setState({ Menu: !this.state.Menu }) }}><Isvg src={close} /></button>
                                            </div>
                                            <div className="menu-options">
                                                <ul>
                                                    <li><Link to="/"><h6 className={this.props[0].location.pathname == '/' ? 'active-page' : ''}>MAIN PAGE</h6></Link></li>
                                                    <li><Link to="/galerija"><h6 className={this.props[0].location.pathname == '/galerija' ? 'active-page' : ''}>GALLERY</h6></Link></li>
                                                    <li><Link to="/kalendar"><h6 className={this.props[0].location.pathname == '/kalendar' ? 'active-page' : ''}>CALENDAR</h6></Link></li>
                                                    <li><Link to="/igrice"><h6 className={this.props[0].location.pathname == '/igrice' ? 'active-page' : ''}>GAMES</h6></Link></li>
                                                    <li><Link to="/stream"><h6 className={this.props[0].location.pathname == '/stream' ? 'active-page' : ''}>STREAMS</h6></Link></li>
                                                    <li><Link to="/novosti"><h6 className={this.props[0].location.pathname == '/novosti' ? 'active-page' : ''}>NEWS</h6></Link></li>
                                                    <li><Link to="/performer"><h6 className={this.props[0].location.pathname == '/performer' ? 'active-page' : ''}>PERFORMER</h6></Link></li>
                                                    <li><Link to="/video"><h6 className={this.props[0].location.pathname == '/video' ? 'active-page' : ''}>VIDEO GALLERY</h6></Link></li>
                                                    <li><Link to="/world-of-fm"><h6 className={this.props[0].location.pathname == '/world-of-fm' ? 'active-page' : ''}>FREEDOM MUSIC WORLDWIDE</h6></Link></li>
                                                    <li><Link to="/people-of-fm"><h6 className={this.props[0].location.pathname == '/people-of-fm' ? 'active-page' : ''}>FREEDOM MUSIC TRIBE</h6></Link></li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                            <section className={this.state.Account ? "account-open" : "account-closed"}>
                                <Container fluid>
                                    <Row>
                                        <Col lg="12">
                                            {
                                                this.state.user ?
                                                    <div className="account-settings">
                                                        <button className="clean-btn account-close-icon" onClick={() => { this.setState({ Account: !this.state.Account, AccountPreview: !this.state.AccountPreview }) }}><Isvg src={close} /></button>
                                                        <div className="account-image">
                                                            <Image value={this.state.user.image} onChange={this.get} />

                                                            {/* <img src={this.state.user.image} />
                                                            <button className="image-btn">Edit photo</button> */}
                                                        </div>
                                                        <div className="account-info">
                                                            <h3>{this.state.user.name}</h3>
                                                            <h6>{this.state.user.username}</h6>
                                                        </div>

                                                        <div className={this.state.AccountPreview ? "account-preview-open" : "account-preview-closed"}>
                                                            <div className="account-info">
                                                                <div className="other-info">
                                                                    <div className="info">
                                                                        <h5>Datum rođenja - <span>&nbsp;{this.state.user.birthday}</span></h5>

                                                                    </div>
                                                                    <div className="info">
                                                                        <h5>Broj telefona - <span>&nbsp;{this.state.user.phoneNumber}</span></h5>
                                                                    </div>
                                                                    <div className="info">
                                                                        <h5>Email - <span>&nbsp;{this.state.user.email}</span></h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="account-btns">
                                                                <div className="edit-btns">
                                                                    <button className="edit-btn bottom26" onClick={() => { this.setState({ EditProfile: !this.state.EditProfile, AccountPreview: !this.state.AccountPreview }) }}>
                                                                        <div className="button-left">
                                                                            <Isvg src={edit} />
                                            Promeni detalje akaunta
                                        </div>
                                                                        <Isvg src={account_right_chevron} />
                                                                    </button>
                                                                    <button className="edit-btn" onClick={() => { this.setState({ ChangePassword: !this.state.ChangePassword, AccountPreview: !this.state.AccountPreview }) }}>
                                                                        <div className="button-left">
                                                                            <Isvg src={password} />
                                            Promeni lozinku
                                        </div>
                                                                        <Isvg src={account_right_chevron} />
                                                                    </button>
                                                                </div>
                                                                <div className="delete-btns">
                                                                    <button className="delete-btn" onClick={this.delete}>Izbriši moj akaunt</button>
                                                                    <button className="logout-btn" onClick={this.logout}>Odjavi se</button>
                                                                </div>

                                                            </div>


                                                        </div>
                                                        <div className={this.state.EditProfile ? "edit-profile-open" : "edit-profile-closed"}>
                                                            <EditProfileForm initialValues={this.state.user} onSubmit={this.update} />
                                                        </div>
                                                        <div className={this.state.ChangePassword ? "change-password-open" : "change-password-closed"}>
                                                            <ChangePasswordForm onSubmit={this.updatePassword} />
                                                        </div>
                                                        {/* <button className="clean-btn" onClick={() => { this.setState({ Account: !this.state.Account }) }}><Isvg src={close} /></button> */}
                                                    </div>
                                                    :
                                                    null
                                            }


                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        </>
                        : null
                }

            </div>
        )
    }

};

export default RightSidebar;