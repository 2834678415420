import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import Slider from '../components/slider';
import ReactGA from 'react-ga';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import Image from '../components/image';

import downChevron from '../assets/svg/down-chevron.svg';
import Newsletter from '../components/newsletter';




class ServicePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }

        }
    }



    render() {
        return (
            <div>
                <section className="page-into">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>Usluge</h1>
                            </Col>
                            <Col lg="6">
                                <ul>
                                    <li><Link to='/'>Početna</Link></li>
                                    <li><Link to='/'>Usluge</Link></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="service-detail-section">

                    <Container>
                        <Row>
                        <Col lg="3" className="categories">
                            <h3>Usluge koje<br/>nudimo</h3>
                            <ul>
                                {
                                    this.state.pages && this.state.pages.map((item, idx) => {
                                        return (
                                            <li className={this.props[0].match.params.alias == Object.translate(item, 'alias', this.props.lang) ? 'active' : null }> <Link to={`/usluge/${Object.translate(item, 'alias', this.props.lang)}`}> {Object.translate(item, 'name', this.props.lang)} </Link> </li>
                                        )
                                    })
                                }
                                </ul>

</Col>

                            <Col lg={{size: 8, offset: 1}} className="detail-content">
                                <Row>
                                    {
                                        this.state.data && this.state.data.content && this.state.data.content.map((item, idx) => {
                                            if (item.type == 'image') {
                                                return (
                                                    <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section">
                                                        <img src={item.value} />
                                                    </Col>
                                                )
                                            } else {
                                                return (
                                                    <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section" dangerouslySetInnerHTML={{ __html: item.value && item.value[this.props.lang] }}>
                                                    </Col>
                                                )
                                            }
                                        })
                                    }


                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>


                <Newsletter socketIOClient={this.props.socketIOClient}></Newsletter>



            </div>
        );
    }
}

export default Page(ServicePage);