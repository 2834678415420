import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import world_map from '../assets/images/world-map.png';

import ReactGA from 'react-ga';


import spotify from '../assets/svg/blog-detail1.svg';
import instagram from '../assets/svg/blog-detail2.svg';
import facebook from '../assets/svg/blog-detail3.svg';




import menu from '../assets/svg/menu-icon.svg';
import right_chevron from '../assets/svg/right-chevron.svg';
import red_right_chevron from '../assets/svg/red-right-chevron.svg';



import Map from '../components/map';

import moment from 'moment';



import Slider from "react-slick";
import BlogArticle from '../components/blogArticle';
import Newsletter from '../components/newsletter';
import map from '../components/map';

var striptags = require('striptags');

class StreamPage extends Component {
    constructor(props) {
        super(props);
        // this.gmapCallback = this.gmapCallback.bind(this);
        // if(typeof window !== "undefined")
        // window.googleMapsCallback = this.gmapCallback;
        this.state = {
            promotedProjects: [],
            ...props.initialData,
            activePage: 0,
            activeIndex: 0,

        };
    }
    // gmapCallback() {
    //     this.setState({
    //         _googleMapsLoaded: true
    //     })
    // }


    // initMap() {
    //     this.setState({
    //         _mapInit: true
    //     });
    //     var latLng = new window.google.maps.LatLng(44.770835, 19.697928
    //     );

    //     var map = new window.google.maps.Map(this.GoogleMap, {
    //         zoom: 2,
    //         center: latLng,
    //         mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    //         disableDefaultUI: true,
    //         gestureHandling: "gestureHandling",


    //     });

    //     let check = false;

    //     for (let i = 0; i < this.state.data.length; i++) {
    //         if (!this.state.data[i].coords) {
    //             continue;
    //         }

    //         if (!check) {
    //             map.setCenter(new window.google.maps.LatLng(this.state.data[i].coords.split(',')[0], this.state.data[i].coords.split(',')[1]))
    //             check = true;
    //         }

    //         var marker = new window.google.maps.Marker({
    //             position: new window.google.maps.LatLng(this.state.data[i].coords.split(',')[0], this.state.data[i].coords.split(',')[1]),
    //             map: map,
    //         });

    //         marker.addListener('click', () => {
    //             this.setState({ Sidebar: true, activeIndex: i })
    //         });


    //     }


    // }
    sidebar = (i) => {
        this.setState({ Sidebar: true, activeIndex: i })
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);
        // var ref = window.document.getElementsByTagName("script")[0];
        // var script = window.document.createElement("script");
        // script.src = "https://maps.googleapis.com/maps/api/js?sensor=false&key=AIzaSyAaSt58UDZVRmMYe52a3cCPfJaoCaHUJqY&callback=googleMapsCallback&language=hr&region=BA";
        // script.async = true;
        // script.defer = true;

        // ref.parentNode.insertBefore(script, ref);


        // if (this.state._googleMapsLoaded && this.state.data && this.state.data.length && !this.state._mapInit) {
        //     this.initMap();
        // }
    }
    // componentDidUpdate(prevProps) {

    //     if (this.state._googleMapsLoaded && this.state.data && this.state.data.length && !this.state._mapInit) {
    //         this.initMap();
    //     }


    // }




    render() {

        return (
            <div className="page-wrap">

                {/* {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null

                } */}
                <section>
                    <Container fluid>
                        <Row className="stream-content">
                            <Col lg="12">
                                <div className="stram-into">
                                    <h1>Freedom Music Worldwide</h1>
                                    <p>
                                    Freedom Music is a global phenomenon that delivers the best music experiences throughout the world. 12 countries, on 4 continents, are connected to the idea of creating memorable parties, where the volume and expectations are always set on high!
<br/>
<br/>
Feel free to explore! The whole world is your dance floor!
                                    </p>
                                </div>

                                <div className="world-map" >
                                    <Map data={this.state.data} {...this.props} sidebar={this.sidebar} />
                                </div>

                            </Col>
                            {
                                this.state.data && this.state.data.map((item, idx) => {
                                    if (this.state.activeIndex == idx)
                                        return (
                                            <div className={this.state.Sidebar ? "world-map-sidebar" : "menu-closed"}>
                                                <div className="stream-info">
                                                    <h2>{Object.translate(item, 'title', this.props.lang)}</h2>
                                                    <p>{Object.translate(item, 'subtitle', this.props.lang)}</p>
                                                    <div className="social-networks">
                                                        {
                                                            Object.translate(item, 'buttonLink1', this.props.lang) ? 
                                                            <a href={Object.translate(item, 'buttonLink1', this.props.lang)} target="_blank"><Isvg src={spotify} className="first-icon" /></a> : null
                                                        }
                                                        {
                                                            Object.translate(item, 'buttonLink2', this.props.lang) ? 
                                                            <a href={Object.translate(item, 'buttonLink2', this.props.lang)} target="_blank"><Isvg src={instagram} /></a> : null
                                                        }
                                                        {
                                                            Object.translate(item, 'buttonLink3', this.props.lang) ? 
                                                            <a href={Object.translate(item, 'buttonLink3', this.props.lang)} target="_blank"><Isvg src={facebook} /></a> : null
                                                        }
                                                        
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div className="close-sidebar">
                                                    <button className="clean-btn" onClick={() => { this.setState({ Sidebar: false }) }}><Isvg src={red_right_chevron} /></button>
                                                </div>
                                            </div>
                                        )
                                })
                            }


                        </Row>

                    </Container>
                </section>
            </div >
        );
    }
}

export default Page(StreamPage);