import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/header';
import Footer from '../components/footer';
import Sidebar from '../components/sidebar';
import RightSidebar from '../components/right-sidebar';

import Checkbox from '../components/forms/fields/checkbox2';

import DeletePrompt from '../components/deletePrompt';
import Prompt from '../components/prompt';

export const DefaultLayout = (Wrapped) => (props) => {
    return (
        // <div>
        //     <Header {...props} />
        //     <Wrapped {...props} />
        //     <Footer {...props} />
        //     !props.cookies && props.lang == 'en' ? <div className="cookies">
        //         <p>We use cookies to personalize and optimize user experience. For more information, visit our <Link to='/privacy-policy' >Privacy Policy</Link>.</p>
        //         <button onClick={() => { props.allowCookies() }}>I AGREE</button>
        //     </div> :
        //         null

        <div className="wrapper">
            {
                props.loader ?
                    <div className="loader">
                        <div className="lds-ripple"><div></div><div></div></div>
                    </div>
                    : null
            }

            {/* <Header {...props} /> */}

            <Sidebar {...props} />
            <RightSidebar {...props} />

            <div className={props.subMenu ? 'sub-menu-active' : props.sidebar ? 'sidebar-active' : ''}>
                <Wrapped {...props} />
            </div>
            {
                props.deletePrompt ?
                    <DeletePrompt
                        {...props}
                    />
                    : null
            }
            {
                props.prompt ?

                    <Prompt
                        {...props}
                    />
                    : null
            }
            {
                false ?
                    <div className="cookies">
                        <div>
                            <p>Freedommusic.rs koristi kolačiće kako bi pružio najbolje iskustvo svakom korisniku naših internet stranica. Nastavkom korišćenja našeg web sajta, slažete se sa upotrebom kolačića <Link to='/kolacici' >(Cookies)</Link>.</p>
                        </div>
                        <div className='cookies-wrap'>
                            <Checkbox
                                onChange={(e) => {
                                    let cookie = props.cookie;
                                    cookie._gid = e.target.checked;
                                    props.setCookie(cookie)

                                }}
                                checked={props.cookie && props.cookie._gid}
                                label={'_gid'.translate(props.lang)}
                            />
                            <Checkbox
                                onChange={(e) => {
                                    let cookie = props.cookie;
                                    cookie._ga = e.target.checked;
                                    props.setCookie(cookie)

                                }}
                                checked={props.cookie && props.cookie._ga}
                                label={'_ga'.translate(props.lang)}
                            />
                            <Checkbox
                                onChange={(e) => {
                                    let cookie = props.cookie;
                                    cookie.VISITOR_INFO1_LIVE = e.target.checked;
                                    props.setCookie(cookie)

                                }}
                                checked={props.cookie && props.cookie.VISITOR_INFO1_LIVE}
                                label={'VISITOR_INFO1_LIVE'.translate(props.lang)}
                            />
                            <Checkbox
                                onChange={(e) => {
                                    let cookie = props.cookie;
                                    cookie.CONSENT = e.target.checked;
                                    props.setCookie(cookie)

                                }}
                                checked={props.cookie && props.cookie.CONSENT}
                                label={'CONSENT'.translate(props.lang)}
                            />
                            <Checkbox
                                onChange={(e) => {
                                    let cookie = props.cookie;
                                    cookie.YSC = e.target.checked;
                                    props.setCookie(cookie)

                                }}
                                checked={props.cookie && props.cookie.YSC}
                                label={'YSC'.translate(props.lang)}
                            />
                            <Checkbox
                                onChange={(e) => {
                                    let cookie = props.cookie;
                                    cookie.test_cookie = e.target.checked;
                                    props.setCookie(cookie)

                                }}
                                checked={props.cookie && props.cookie.test_cookie}
                                label={'test_cookie'.translate(props.lang)}
                            />
                            <button onClick={() => { props.allowCookies() }} className="allow-btn">Prihvatam</button>
                            <button onClick={() => { props.disallowCookies() }} className="disallow-btn">Ne, hvala</button>
                        </div>



                    </div> :
                    null

            }

        </div>

        // </div>
    );
};

export default DefaultLayout;
