import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';



import logo from '../assets/svg/landing-logo.svg';

import webIcon from '../assets/images/webIcon.png';

import fbIcon from '../assets/images/fbIcon.png';

import instIcon from '../assets/images/instaIcon.png';



var striptags = require('striptags');

class LandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }



        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);



    }

    componentDidUpdate(prevProps, prevState) {
    }


    render() {



        return (
            <div className="social-box-container">
                <div className="social-box box-1">
                    <a >
                        <Isvg src={logo} />
                    </a>
                    <div className="social-box-content">
                        <a href="https://freedommusic.rs">
                            <img src={webIcon} />
                        </a>
                    </div>
                </div>
                <div className="social-box box-2">
                    <a>
                        <Isvg src={logo} />
                    </a>
                    <div className="social-box-content">
                        <a href="https://www.instagram.com/freedommusicsrbija/">
                            <img src={instIcon} />
                        </a>
                    </div>
                </div>
                <div className="social-box box-3">
                    <a>
                        <Isvg src={logo} />
                    </a>
                    <div className="social-box-content">
                        <a href="https://www.facebook.com/freedommusicsrbija">
                            <img src={fbIcon} />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default LandingPage;