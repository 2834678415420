import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import ReactGA from 'react-ga';
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input,
    FormGroup,
    Label
} from 'reactstrap';


import red_right_chevron from '../assets/svg/red-right-chevron.svg';
import right_chevron from '../assets/svg/right-chevron.svg';
import music from '../assets/images/music.jpg';

import close_ico from '../assets/svg/close-ico.svg';
import lightbox_dots from '../assets/svg/lightbox-dots.svg';


import new_image from '../assets/images/news-page-image.png';
import youtube from '../assets/images/youtube.png';

import calendar from '../assets/svg/calendar-blog-detail.svg';
import spotify from '../assets/svg/blog-detail1.svg';
import instagram from '../assets/svg/blog-detail2.svg';
import facebook from '../assets/svg/blog-detail3.svg';

import Slider from "react-slick";
import BlogArticle from '../components/blogArticle';
import Newsletter from '../components/newsletter';
import ReactPaginate from 'react-paginate';

var striptags = require('striptags');


function msToTime(duration) {
    var milliseconds = (duration % 1000),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    milliseconds = (milliseconds < 10) ? "00"+ milliseconds : (milliseconds < 100 && milliseconds > 9) ? "0"+ milliseconds : milliseconds;

    milliseconds = (milliseconds < 10) ? "00"+ milliseconds : (milliseconds < 100 && milliseconds > 9) ? "0"+ milliseconds : milliseconds;

    // return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
    return minutes + ":" + seconds + "." + milliseconds;
}


class ResultsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            order: -1

        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        let alias = this.props[0].match.params.alias && this.props[0].match.params.alias !== 'svi' && this.props[0].match.params.alias !== 'bodovi' ? this.props[0].match.params.alias : null


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, alias).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

            let alias = this.props[0].match.params.alias && this.props[0].match.params.alias !== 'svi' && this.props[0].match.params.alias !== 'bodovi' ? this.props[0].match.params.alias : null


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, alias).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }
        if (JSON.stringify(prevState.gamesResults) != JSON.stringify(this.state.gamesResults)) {
            this.checkSort()
        }
    }
    insertResult = (username, points, game) => {
        let data = {
            username: username,
            points: points,
            game: game
        };
        fetch('https://api.freedommusic.rs/games/results/add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {
        })
    }
    checkSort = () => {
        if (this.state.gamesResults && this.state.order) {
            let gamesResults = this.state.gamesResults;
            if (this.state.order == 1) {
                gamesResults.sort((a, b) => a.points > b.points ? 1 : -1);
            } else if (this.state.order == -1) {
                gamesResults.sort((a, b) => a.points < b.points ? 1 : -1);
            }

            this.setState({ gamesResults })

        }
    }



    render() {

        return (
            <div className="page-wrap" style={{ overflowY: 'hidden' }}>
                {/* {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null

                } */}

                <div className="games-result-page-wrap" style={{ backgroundImage: `url(${this.state.settings && this.state.settings.image})` }}>
                    <table>
                        <tr>
                            <td colSpan='4'>
                                <div className='table-head-wrap'>
                                    <h3>RESULTS</h3>
                                    {/* <FormGroup className="sort-wrap">
                                        <Label>Sort</Label>
                                        <Input type='select' value={this.state.order} onChange={(e) => {
                                            this.setState({ order: e.target.value }, () => this.checkSort())
                                        }}>
                                            <option value={1}>{'Ascending'}</option>
                                            <option value={-1}>{'Descending'}</option>
                                        </Input>
                                    </FormGroup> */}
                                </div>
                            </td>

                        </tr>
                        <tr>
                            <td><h4>No</h4></td>
                            <td><h4>USERNAME</h4></td>
                            <td><h4>RESULT</h4></td>
                        </tr>
                        {
                            this.state.gamesResults && this.state.gamesResults.map((item, idx) => {
                                return (
                                    <tr>
                                        <td><h5>{idx + 1}</h5></td>
                                        <td><h5>{item.username}</h5></td>
                                        <td>
                                            <h5>
                                                {/* {[String(item.points).slice(0, -3), ".", String(item.points).slice(-3)].join('')} */}
                                                {item.points ? item.points : item.time ? item.time : 0}
                                            </h5>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </table>
                </div>

            </div>
        );
    }
}

export default Page(ResultsPage);
