import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import ReactGA from 'react-ga';
import spotify from '../assets/svg/blog-detail1.svg';
import instagram from '../assets/svg/blog-detail2.svg';
import facebook from '../assets/svg/blog-detail3.svg';
import play from '../assets/svg/play-stream.svg';
import stream_image from '../assets/images/stream-img.png';


import EventField from '../components/eventField';

import account from '../assets/svg/account-icon.svg';
import account_image from '../assets/images/account-image.png';

import menu from '../assets/svg/menu-icon.svg';
import right_chevron from '../assets/svg/right-chevron.svg';
import red_right_chevron from '../assets/svg/red-right-chevron.svg';
import account_right_chevron from '../assets/svg/account-right-chevron.svg';
import edit from '../assets/svg/edit.svg';
import password from '../assets/svg/password.svg';
import left_chevron from '../assets/svg/left-chevron.svg';
import top_chevron from '../assets/svg/top-chevron.svg';
import search from '../assets/svg/search-icon.svg';
import close from '../assets/svg/close-icon.svg';
import music from '../assets/images/music.jpg';

import Text from '../components/forms/fields/text'
import ChangePasswordForm from '../components/forms/changePasswordForm';
import EditProfileForm from '../components/forms/editProfileForm';



import Map from '../components/map';

import moment from 'moment';



import Slider from "react-slick";
import BlogArticle from '../components/blogArticle';
import Newsletter from '../components/newsletter';

var striptags = require('striptags');

class StreamPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promotedProjects: [],
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }





    render() {


        console.log(Object.get(this.state, 'section[0].image'))
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: typeof window !== 'undefined' && window.innerWidth < 768 ? 1 : 3,
            slidesToScroll: 1
        };

        return (
            <div className="page-wrap">
                {/* {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null

                } */}

                <section>
                    <Container fluid>
                        {
                            this.state.data ?
                                <Row className="stream-content ">
                                    <Col lg="12">
                                        <div className="stram-into stream-padding">
                                            <h1>{Object.translate(this.state.data, 'title', this.props.lang)}</h1>
                                            <p>{Object.translate(this.state.data, 'subtitle', this.props.lang)}</p>
                                        </div>

                                    </Col>
                                    <Col lg="9">
                                        <div className="stream-player" dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'stream', this.props.lang) }}>
                                            {/* <img src={stream_image} />
                                            <div className="play-stream">
                                                <button className="clean-btn"><Isvg src={play} /></button>
                                            </div>
                                            <div className="title">
                                                <h6>Lorem ipsum</h6>
                                                <div className="time">
                                                    <h6>00:00</h6>
                                                </div>
                                            </div> */}
                                            
                                        </div>
                                        
                                    </Col>

                                    <Col lg="3">
                                        <div className="stream-info">
                                            <h2>{Object.translate(this.state.data, 'title2', this.props.lang)}</h2>
                                            <p>{Object.translate(this.state.data, 'subtitle2', this.props.lang)} </p>
                                            <div className="social-networks">
                                                {
                                                    Object.translate(this.state.data, 'buttonLink1', this.props.lang) ?
                                                    <a href={Object.translate(this.state.data, 'buttonLink1', this.props.lang)} target="_blank"><Isvg src={spotify} className="first-icon" /></a> : null
                                                }
                                                {
                                                    Object.translate(this.state.data, 'buttonLink2', this.props.lang) ?
                                                    <a href={Object.translate(this.state.data, 'buttonLink2', this.props.lang)} target="_blank"><Isvg src={instagram} /></a> : null
                                                }
                                                {
                                                    Object.translate(this.state.data, 'buttonLink3', this.props.lang) ?
                                                    <a href={Object.translate(this.state.data, 'buttonLink3', this.props.lang)} target="_blank"><Isvg src={facebook} /></a> : null
                                                }
                                                
                                            
                                            </div>
                                        </div>
                                    </Col>
                                    <div className="stream-button-field">
                                        {/* <Link to="/"> */}
                                            <button className="stream-btn">WATCH LIVE STREAM</button>
                                        {/* </Link> */}
                                    </div>
                                </Row>
                                :
                                null
                        }

                    </Container>
                </section>
            </div >
        );
    }
}

export default Page(StreamPage);