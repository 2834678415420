import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import Iframe from 'react-iframe';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';
import ReactGA from 'react-ga';

import performer from '../assets/images/performer.png';
import game from '../assets/images/game.png';
import preporuka from '../assets/images/preporuka.png';

import red_right_chevron from '../assets/svg/red-right-chevron.svg';
import top_chevron from '../assets/svg/top-chevron.svg';
import calendar from '../assets/svg/calendar-blog-detail.svg';
import spotify from '../assets/svg/blog-detail1.svg';
import instagram from '../assets/svg/blog-detail2.svg';
import facebook from '../assets/svg/blog-detail3.svg';


var striptags = require('striptags');



class GamesDetailPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        document.body.style.backgroundColor = '#0B2B75';

        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);

    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }


    }
    // insertResult = (username, points, game) => {
    //     let data = {
    //         username: username,
    //         points: points,
    //         game: game
    //     };
    //     fetch('https://api.freedommusic.rs/games/results/add', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${localStorage.getItem('token')}`
    //         },
    //         body: JSON.stringify(data)
    //     }).then((res) => res.json()).then((result) => {
    //         console.log(result);
    //     })
    // }




    render() {

        return (
            <div className="play-game-wrap">
                {/* {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null

                } */}
                <section>
                    <Container fluid>
                        <Row className="performer">
                            
                            <Col lg="12">
                                {/* <div className="performer-image overlay overlay2">
                                    <img src={Object.get(this.state.data, 'image')} />
                                </div> */}
                                <div className="performer-info">
                                    <h1>{Object.translate(this.state.data, 'name', this.props.lang)}</h1>

                                    <p>{Object.translate(this.state.data, 'description', this.props.lang)}</p>
                                    <div className="games-wrap">
                                        <div className="play-game">
                                            <Iframe url={Object.get(this.state.data, 'buttonLink')}
                                                id="myId"
                                                className="iframe"
                                                allow="fullscreen"
                                            />
                                        </div>
                                    </div>
                                  
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

export default GamesDetailPage;