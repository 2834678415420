import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Isvg from 'react-inlinesvg';

import red_right_chevron from '../assets/svg/red-right-chevron.svg';
import music from '../assets/images/music.jpg';
import top_chevron from '../assets/svg/top-chevron.svg';


export class BlogArticle extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    render() {
        console.log(this.props.active);
        return (
            // <Link to={`/novosti/${Object.translate(this.props, 'alias', this.props.lang)}`}>
            <div className="event-field">
                {
                    this.props && this.props.active ?
                        <div className="home-field overlay field-info-overlay">
                            <img src={Object.get(this.props.info, 'image')} />
                            <div className="field-info">
                                <div className="info-text">
                                    <h3>{Object.translate(this.props.info, 'firstName', this.props.lang)} {Object.translate(this.props.info, 'lastName', this.props.lang)}</h3>
                                    <p>{Object.translate(this.props.info, 'description', this.props.lang)}</p>
                                </div>
                                <button className="clean-btn"><Isvg src={red_right_chevron} /></button>
                            </div>
                        </div>
                        :
                        <div className="home-field overlay">
                            <img src={Object.get(this.props.info, 'image')} />
                            <button className="field-preview field-preview-overlay" >
                                <h6>{Object.translate(this.props.info, 'firstName', this.props.lang)} {Object.translate(this.props.info, 'lastName', this.props.lang)}</h6>
                                <Isvg src={top_chevron} />
                            </button>

                        </div>
                }
            </div>

            // </Link>
        )
    }
}

export default BlogArticle;