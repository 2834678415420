import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import ReactGA from 'react-ga';
import performer from '../assets/images/performer.png';
import performer2 from '../assets/images/performer2.png';

import youtube from '../assets/images/youtube.png';

import red_right_chevron from '../assets/svg/red-right-chevron.svg';
import top_chevron from '../assets/svg/top-chevron.svg';

import EventField from '../components/eventField';
import Slider from "react-slick";
import BlogArticle from '../components/blogArticle';
import Newsletter from '../components/newsletter';
import ReactPaginate from 'react-paginate';

var striptags = require('striptags');



class PerformerPage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);

        this.state = {
            ...props.initialData,
            page: 0,
            activeIndex: 0
        };
    }
    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        //console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
        if (prevProps.page != this.props.page) {
            if (this.props.page >= this.state.total / 9)
                return;

            this.updateParam('page', this.props.page);
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        this.props.setPage(0);

        this.updateStateFromSearch(this.get);

        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }
    get() {
        fetch('https://api.freedommusic.rs/performers', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: this.state.page,
                lang: this.props.lang
            })
        }).then(res => res.json()).then((result) => {
            console.log(result);
            this.setState({
                performers: result.items,
                total: result.total
            })
            this.props.setPageTotal(result.total);
        })

    }




    render() {

        return (
            <div className="page-wrap">
                {/* {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null

                } */}
                <section>
                    <Container fluid>
                        <Row className="home-content">
                            {
                                this.state.performers && this.state.performers.map((item, idx) => {
                                    return (
                                        <Col lg="4">
                                            <div className="event-field">
                                                <Link lang={this.props.lang} to={`/performer/${Object.translate(item, 'alias', this.props.lang)}`}>
                                                    <div className="home-field overlay field-info-overlay">
                                                        <img src={Object.get(item, 'image')} />
                                                        <div className="field-preview field-preview-overlay">
                                                            <h6>{Object.translate(item, 'name', this.props.lang)}</h6>
                                                            <Isvg src={top_chevron} />
                                                        </div>
                                                        <div className="field-info">
                                                            <div className="info-text">
                                                                <h3>{Object.translate(item, 'name', this.props.lang)}</h3>
                                                                <p>{Object.translate(item, 'description', this.props.lang)}</p>
                                                            </div>
                                                            {/* <Link lang={this.props.lang} to={`/performer/${Object.translate(item, 'alias', this.props.lang)}`}> */}
                                                            <button className="clean-btn"><Isvg src={red_right_chevron} /></button>
                                                            {/* </Link> */}
                                                        </div>

                                                    </div>
                                                </Link>
                                            </div>
                                        </Col>
                                    )
                                })
                            }

                        </Row>
                        {/* {this.state.total / 9 > 1 ?
                            <Row>
                                <Col lg="12">
                                    <ReactPaginate
                                        previousLabel={''.translate(this.props.lang)}
                                        nextLabel={''.translate(this.props.lang)}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.total / 9}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={(page) => { this.updateParam('page', page.selected) }}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                        hrefBuilder={(page) => { return `?page=${page}` }}
                                    //forcePage={this.state.page}
                                    />

                                </Col>

                            </Row>

                            :

                            null
                        } */}
                    </Container>
                </section>
            </div>
        );
    }
}

export default Page(PerformerPage);