import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import moment from 'moment';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';


import performer from '../assets/images/performer.png';
import video from '../assets/images/video.png';
import preporuka from '../assets/images/preporuka.png';

import red_right_chevron from '../assets/svg/red-right-chevron.svg';
import top_chevron from '../assets/svg/top-chevron.svg';
import calendar from '../assets/svg/calendar-blog-detail.svg';
import spotify from '../assets/svg/blog-detail1.svg';
import instagram from '../assets/svg/blog-detail2.svg';
import facebook from '../assets/svg/blog-detail3.svg';

import ReactGA from 'react-ga';
import Slider from "react-slick";
import BlogArticle from '../components/blogArticle';
import Newsletter from '../components/newsletter';
import ReactPaginate from 'react-paginate';

var striptags = require('striptags');



class PerformerDetailPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);

    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }


    }



    render() {

        return (
            <div className="page-wrap">
                {/* {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null

                } */}
                <section>
                    <Container fluid>
                        <Row className="performer">
                            <Col lg="12">
                                <div className="performer-image overlay overlay2">
                                    <img src={Object.get(this.state.data, 'image')} />
                                </div>
                                <div className="performer-info">
                                    <h1>{Object.translate(this.state.data, 'name', this.props.lang)}</h1>
                                    <div className="info">
                                        <h5>Rođen:
                                        <span>&nbsp;{Object.translate(this.state.data, 'birth', this.props.lang)}</span>
                                        </h5>
                                        <h5>Žanr:
                                        <span>&nbsp;{Object.translate(this.state.data, 'type', this.props.lang)}</span>
                                        </h5>
                                        <h5>Diskografske kuće:
                                        <span>&nbsp;{Object.translate(this.state.data, 'publisher', this.props.lang)}</span>
                                        </h5>
                                    </div>
                                    {
                                        this.state.data && this.state.data.content && this.state.data.content.map((item, idx) => {
                                            if (item.type == 'image') {
                                                return (
                                                    <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section no-padding-col">
                                                        <img src={item.value} />
                                                    </Col>
                                                )
                                            } else {
                                                return (
                                                    <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section no-padding-col" dangerouslySetInnerHTML={{ __html: item.value && item.value[this.props.lang] }}>
                                                    </Col>
                                                )
                                            }
                                        })
                                    }

                                    <h2>Nastupi</h2>
                                    <div className="performances">
                                        <table>
                                            {
                                                this.state.data && this.state.data.performance && this.state.data.performance.map((item, idx) => {
                                                    return (
                                                        item.linkToEvent ?
                                                            <Link lang={this.props.lang} to={`/kalendar/${Object.translate(item, 'linkToEvent', this.props.lang)}`}>
                                                                <tr>
                                                                    <td><Isvg src={item.image} /></td>
                                                                    <td><h4>{moment.unix(item.date && item.date).format('DD.MM.YYYY.')}</h4></td>
                                                                    <td><h4> {Object.translate(item, 'event', this.props.lang)}</h4></td>
                                                                </tr>
                                                            </Link>
                                                            :
                                                            <tr>
                                                                <td><Isvg src={item.image} /></td>
                                                                <td><h4>{moment.unix(item.date && item.date).format('DD.MM.YYYY.')}</h4></td>
                                                                <td><h4> {Object.translate(item, 'event', this.props.lang)}</h4></td>
                                                            </tr>

                                                    )
                                                })
                                            }
                                            {/* <tr>
                                                <td><Isvg src={calendar} /></td>
                                                <td><h4>25.04.2018.</h4></td>
                                                <td><h4> “Green love” Novi Sad, Spens</h4></td>
                                            </tr>
                                            <tr>
                                                <td><Isvg src={calendar} /></td>
                                                <td><h4>25.04.2018.</h4></td>
                                                <td><h4> “Green love” Novi Sad, Spens</h4></td>
                                            </tr>
                                            <tr>
                                                <td><Isvg src={calendar} /></td>
                                                <td><h4>25.04.2018.</h4></td>
                                                <td><h4> “Green love” Novi Sad, Spens</h4></td>
                                            </tr> */}
                                        </table>
                                    </div>

                                    <div className="performer-social-networks">
                                        {
                                            Object.translate(this.state.data, 'buttonLink1', this.props.lang) ? 
                                            <a href={Object.translate(this.state.data, 'buttonLink1', this.props.lang)} target="_blank"><Isvg src={instagram} /></a> : null
                                        }
                                        {
                                            Object.translate(this.state.data, 'buttonLink2', this.props.lang) ? 
                                            <a href={Object.translate(this.state.data, 'buttonLink2', this.props.lang)} target="_blank"><Isvg src={facebook} /></a> : null
                                        }
                                        
                                        
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

export default Page(PerformerDetailPage);