import React, { Component } from 'react';
// import Link from '../components/link';
import { Link, Redirect } from 'react-router-dom';


import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';
import ReactGA from 'react-ga';
import Form from '../components/forms/loginForm';



var striptags = require('striptags');

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {

        };
    }

    componentDidMount() {

        // if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        // for (let i = 0; i < this.props.loadData.length; i++) {
        //     this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
        //         this.setState({
        //             ...data
        //         })
        //     })
        // }
        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }



    submit(data) {
        console.log(data);

        fetch('https://api.freedommusic.rs/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {
            if (!result.error) {
                let token = result.token;
                localStorage.setItem('token', token);
                this.props[0].history.push('/');
            } else {
                this.setState({
                    error: result.error
                })
            }
        })
    }



    render() {



        return (
            <div className="">
                {/* {
                    typeof localStorage !== 'undefined' && localStorage.token ? <Redirect to='/' /> : null

                } */}
                <Redirect to='/' /> 
                <Container fluid>

                    <Row>
                        <div className="login-wrap">
                            <div className="login-page">
                                <Col lg="12">
                                    <div className="login">
                                        <h1>LOGIN</h1>
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <Form onSubmit={this.submit} />
                                    {this.state.error ?
                                        <div className="error-msg">
                                            <p>{this.state.error}</p>
                                        </div>
                                        :
                                        null
                                    }

                                </Col>
                            </div>
                        </div>
                    </Row>

                </Container>

            </div>
        );
    }
}

export default Page(LoginPage);