import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import moment from 'moment';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';


import new_image from '../assets/images/new-image.png';
import calendar from '../assets/svg/calendar-icon.svg';
import timelinePoint from '../assets/svg/timeline-point.svg';
import red_right_chevron from '../assets/svg/red-right-chevron.svg';


import Slider from "react-slick";
import BlogArticle from '../components/blogArticle';
import Newsletter from '../components/newsletter';
import ReactPaginate from 'react-paginate';
import ReactGA from 'react-ga';
var striptags = require('striptags');

class CalendarPage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);

        this.state = {
            promotedProjects: [],
            page: 0,
            ...props.initialData,
            activeIndex: 0,
            activeMonth: 0,
            // activeYear: new Date(Math.floor(new Date().getTime())).getFullYear()
        };
    }

    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        //console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
      
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);

        this.updateStateFromSearch(this.get);


    }
    get() {
        fetch('https://api.freedommusic.rs/event', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: this.state.page,
                lang: this.props.lang
            })
        }).then(res => res.json()).then((result) => {
            console.log(result);
            this.setState({
                items: result.items,
                total: result.total
            })
        })

    }


    render() {
        console.log(this.state);
        return (
            <div className="page-wrap">
                {/* {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null

                } */}
                <section>
                    <Container fluid>
                        <Row className="calendar-timeline">
                            <Col lg="12">
                                <div className="timeline">
                                    <div className="line">
                                        <hr />
                                        {
                                            this.props.activeYear ?
                                                <div className="months">
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.activeMonth && this.state.activeMonth == 1 ? "active-month clean-btn " : "month-btn clean-btn"} onClick={() => { this.setState({ activeMonth: 1 }) }}><div className="button-field"><Isvg src={timelinePoint} />JANUAR <p>{this.props.activeYear}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.activeMonth && this.state.activeMonth == 2 ? "active-month clean-btn " : "month-btn clean-btn"} onClick={() => { this.setState({ activeMonth: 2 }) }}><div className="button-field"><Isvg src={timelinePoint} />FEBRUAR<p>{this.props.activeYear}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.activeMonth && this.state.activeMonth == 3 ? "active-month clean-btn " : "month-btn clean-btn"} onClick={() => { this.setState({ activeMonth: 3 }) }}><div className="button-field"><Isvg src={timelinePoint} />MART<p>{this.props.activeYear}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.activeMonth && this.state.activeMonth == 4 ? "active-month clean-btn " : "month-btn clean-btn"} onClick={() => { this.setState({ activeMonth: 4 }) }}><div className="button-field"><Isvg src={timelinePoint} />APRIL<p>{this.props.activeYear}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.activeMonth && this.state.activeMonth == 5 ? "active-month clean-btn " : "month-btn clean-btn"} onClick={() => { this.setState({ activeMonth: 5 }) }}><div className="button-field"><Isvg src={timelinePoint} />MAJ<p>{this.props.activeYear}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.activeMonth && this.state.activeMonth == 6 ? "active-month clean-btn " : "month-btn clean-btn"} onClick={() => { this.setState({ activeMonth: 6 }) }}><div className="button-field"><Isvg src={timelinePoint} />JUN<p>{this.props.activeYear}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.activeMonth && this.state.activeMonth == 7 ? "active-month clean-btn " : "month-btn clean-btn"} onClick={() => { this.setState({ activeMonth: 7 }) }}><div className="button-field"><Isvg src={timelinePoint} />JUL<p>{this.props.activeYear}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.activeMonth && this.state.activeMonth == 8 ? "active-month clean-btn " : "month-btn clean-btn"} onClick={() => { this.setState({ activeMonth: 8 }) }}><div className="button-field"><Isvg src={timelinePoint} />AVGUST<p>{this.props.activeYear}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.activeMonth && this.state.activeMonth == 9 ? "active-month clean-btn " : "month-btn clean-btn"} onClick={() => { this.setState({ activeMonth: 9 }) }}><div className="button-field"><Isvg src={timelinePoint} />SEPTEMBAR<p>{this.props.activeYear}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.activeMonth && this.state.activeMonth == 10 ? "active-month clean-btn " : "month-btn clean-btn"} onClick={() => { this.setState({ activeMonth: 10 }) }}><div className="button-field"><Isvg src={timelinePoint} />OKTOBAR<p>{this.props.activeYear}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.activeMonth && this.state.activeMonth == 11 ? "active-month clean-btn " : "month-btn clean-btn"} onClick={() => { this.setState({ activeMonth: 11 }) }}><div className="button-field"><Isvg src={timelinePoint} />NOVEMBAR<p>{this.props.activeYear}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.activeMonth && this.state.activeMonth == 12 ? "active-month clean-btn " : "month-btn clean-btn"} onClick={() => { this.setState({ activeMonth: 12 }) }}><div className="button-field"><Isvg src={timelinePoint} />DECEMBAR<p>{this.props.activeYear}</p></div></button>
                                                    </Col>
                                                </div>
                                                :
                                                null
                                        }


                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="news-section">
                            {
                                this.state.items && this.state.items.map((item, idx) => {
                                    if (this.state.activeMonth == 0 && this.props.activeYear == item.year)
                                        return (
                                            <Col lg="12">
                                                <div className="news-field-wrap">
                                                    <Link lang={this.props.lang} to={`/kalendar/${Object.translate(item, 'alias', this.props.lang)}`}>
                                                        <div className="news-field active-border">
                                                            <div className="news-image overlay active-border-image">
                                                                <img src={Object.get(item, 'image')} />
                                                            </div>

                                                            <div className="news-info active-padding">
                                                                <h3>{Object.translate(item, 'title', this.props.lang)}</h3>
                                                                <p>{Object.translate(item, 'shortDescription', this.props.lang)} </p>
                                                                <div className="active-button">
                                                                    {/* <Link lang={this.props.lang} to={`/kalendar/${Object.translate(item, 'alias', this.props.lang)}`}> */}
                                                                    <div className="news-more">
                                                                        <button className="clean-btn"><Isvg src={red_right_chevron} /></button>
                                                                        <h4>CLICK FOR MORE</h4>
                                                                    </div>
                                                                    {/* </Link> */}
                                                                </div>
                                                            </div>

                                                            <div className="news-date">
                                                                <h6>{moment.unix(item.date && item.date).format('DD.MM.YYYY')}</h6>
                                                                <Isvg src={calendar} />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </Col>
                                        )
                                    else if (this.props.activeYear == item.year && this.state.activeMonth == item.month)
                                        return (
                                            <Col lg="12">
                                                <div className="news-field-wrap">
                                                    <Link lang={this.props.lang} to={`/kalendar/${Object.translate(item, 'alias', this.props.lang)}`}>
                                                        <div className="news-field active-border">
                                                            <div className="news-image overlay active-border-image">
                                                                <img src={Object.get(item, 'image')} />
                                                            </div>

                                                            <div className="news-info active-padding">
                                                                <h3>{Object.translate(item, 'title', this.props.lang)}</h3>
                                                                <p>{Object.translate(item, 'shortDescription', this.props.lang)} </p>
                                                                <div className="active-button">
                                                                    {/* <Link lang={this.props.lang} to={`/kalendar/${Object.translate(item, 'alias', this.props.lang)}`}> */}
                                                                    <div className="news-more">
                                                                        <button className="clean-btn"><Isvg src={red_right_chevron} /></button>
                                                                        <h4>CLICK FOR MORE</h4>
                                                                    </div>
                                                                    {/* </Link> */}
                                                                </div>
                                                            </div>

                                                            <div className="news-date">
                                                                <h6>{moment.unix(item.date && item.date).format('DD.MM.YYYY')}</h6>
                                                                <Isvg src={calendar} />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </Col>
                                        )


                                })
                            }
                        </Row>
                         {/* {this.state.total / 9 > 1 ?
                            <Row>
                                <Col lg="12">
                                    <ReactPaginate
                                        previousLabel={''.translate(this.props.lang)}
                                        nextLabel={''.translate(this.props.lang)}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.total / 9}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={(page) => { this.updateParam('page', page.selected) }}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                        hrefBuilder={(page) => { return `?page=${page}` }}
                                    //forcePage={this.state.page}
                                    />

                                </Col>

                            </Row>

                            :

                            null
                        } */}
                    </Container>
                </section>




            </div>
        );
    }
}

export default Page(CalendarPage);