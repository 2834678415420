import HomePage from './views/homePage';
import AboutUsPage from './views/aboutUsPage';
import ContactPage from './views/contactPage';
import ServicePage from './views/servicePage';
import BlogPage from './views/blogPage';
import BlogDetailPage from './views/blogDetailPage';
import HomeDetailPage from './views/homePageNewsDetail';

import Service1Page from './views/service1Page';
import GalleryPage from './views/galleryPage';
import GalleryDetailPage from './views/galleryDetailPage';
import PerformerPage from './views/performerPage';
import PerformerDetailPage from './views/performerDetailPage';
import CalendarPage from './views/calendarPage';
import CalendarDetailPage from './views/calendarDetailPage';
import StreamPage from './views/streamPage';
import SocialMedia from './views/socialMediaPage';
import WorldOfFM from './views/worldOfFMPage';
import PeopleOfFM from './views/peopleOfFMPage';
import GamesPage from './views/gamesPage';
import GamesDetailPage from './views/gamesDetail';
import PlayGamePage from './views/playGamePage';

import AccountVerifyPage from './views/accountVerifyPage';
import ResultsPage from './views/resultsPage';
import CookiesPage from './views/cookiesPage';
import ResultsPointsPage from './views/resultsPointsPage';

import LoginPage from './views/loginPage';
import ResetPasswordPage from './views/resetPasswordPage';
import ResetPassword from './views/resetPassword';
import RegisterPage from './views/registerPage';
import LandingPage from './views/landingPage';


const linkTranslates = {

    'en': {
        '/': '/en',
        '/usluge/:alias': '/en/services/:alias',
        '/servis/:alias': '/en/servis/:alias',
        '/o-nama': '/en/about-us',
        '/novosti': '/en/blog',
        '/novosti/:alias': '/en/blog/:alias',
        '/kontakt': '/en/contact',
        '/prijava': '/en/login',
        '/registracija': '/en/register',
        '/galerija': '/en/gallery',
        '/galerija/:alias': '/en/gallery/:alias',
        '/performer': '/en/performer',
        '/performer/:alias': '/en/performer/:alias',
        '/kalendar': '/en/calendar',
        '/kalendar/:alias': '/en/calendar/:alias',
        '/stream': '/en/stream',
        '/video': '/en/video',
        '/world-of-fm': '/en/world-of-fm',
        '/people-of-fm': '/en/people-of-fm',
        '/igrice': '/en/games',
        '/igrice/:alias': '/en/games/:alias',
        '/igrice/:alias/play': '/en/games/:alias/play',
        '/igrice/rezultati/svi': '/en/games/results/all',
        '/igrice/rezultati/bodovi': '/en/games/results/points',
        '/account/verify/:id/:emailVerificationCode': '/account/verify/:id/:emailVerificationCode',
        '/reset-password': '/en/reset-password',
        '/reset-password/:uid/:resetPasswordVerificationCode': '/en/reset-password/:uid/:resetPasswordVerificationCode',
        '/igrice/rezultati/svi': '/en/games/results/all',
        '/igrice/rezultati/bodovi': '/en/games/results/points',
        '/igrice/rezultati/:alias': '/en/games/results/:alias',
        '/kolacici': '/en/cookies',
        '/pocetna/:alias': '/en/home/:alias',
        '/landingpage': '/en/landingpage'
    },
}

export const routes = [
    {
        path: "/",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Main page'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: HomePage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.freedommusic.rs/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        data
                    }
                })

            },
        ],

        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction('https://api.freedommusic.rs/performers', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: 0, //query.page
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        page: query.page ? query.page : 0,
                        performers: result.items,
                        total: result.total
                    }
                })

            }
        ]


    },
    {
        path: "/kolacici",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Cookies'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: CookiesPage,
        loadData: [

        ],

        loadDataWithQuery: [

        ]


    },
    {
        path: '/landingpage',
        generateSeoTags: (data, lang) => {
            return {
                title: 'Freedoom Music'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: LandingPage,
        loadData: [

        ],

        loadDataWithQuery: [

        ]
    },

    {
        path: "/account/verify/:id/:emailVerificationCode",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Account verify'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: AccountVerifyPage,
        loadData: []
    },
    {
        path: "/prijava",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Login'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: LoginPage,
        loadData: []
    },
    {
        path: "/reset-password",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Reset password'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: ResetPasswordPage,
        loadData: []
    },
    {
        path: "/reset-password/:uid/:resetPasswordVerificationCode",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Reset password'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: ResetPassword,
        loadData: []
    },

    {
        path: "/registracija",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Registration'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: RegisterPage,
        loadData: []
    },
    {
        path: "/galerija",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Gallery'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: GalleryPage,
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction('https://api.freedommusic.rs/gallery', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: 0, //query.page
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        page: query.page ? query.page : 0,
                        gallery: result.items,
                        total: result.total
                    }
                })

            }
        ]

    },
    {
        path: "/galerija/:alias",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Gallery'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: GalleryDetailPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://api.freedommusic.rs/gallery/${lang}/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        gallery: result,
                        images: result.images
                    }
                })

            }
        ]
    },

    {
        path: "/performer",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Performer'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: PerformerPage,
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction('https://api.freedommusic.rs/performers', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: 0, //query.page
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        page: query.page ? query.page : 0,
                        performers: result.items,
                        total: result.total
                    }
                })

            }
        ]
        // loadData: [
        //     (fetchFunction, lang, match, query) => {
        //         return fetchFunction('https://api.freedommusic.rs/performers', {
        //             method: 'GET',
        //             headers: {
        //                 'content-type': 'application/json'
        //             },

        //         }).then(res => res.json()).then((result) => {
        //             return {
        //                 performers: result
        //             }
        //         })
        //     }
        // ]
    },
    {
        path: "/performer/:alias",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Performer'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: PerformerDetailPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://api.freedommusic.rs/performers/detail/${lang}/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            }
        ]
    },

    {
        path: "/kalendar",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Calendar'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: CalendarPage,
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction('https://api.freedommusic.rs/event', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: 0, //query.page
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        page: query.page ? query.page : 0,
                        items: result.items,
                        total: result.total
                    }
                })

            }
        ]
    },
    {
        path: "/kalendar/:alias",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Calendar'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: CalendarDetailPage,
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(`https://api.freedommusic.rs/event/performers/${lang}/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {
                    return {
                        performers: result
                    }
                })
            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://api.freedommusic.rs/event/detail/${lang}/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            }

        ]
    },
    {
        path: "/stream",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Stream'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: StreamPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.freedommusic.rs/stream', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        data
                    }
                })

            }
        ]
    },
    {
        path: "/video",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Video gallery'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: SocialMedia,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.freedommusic.rs/video', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        data
                    }
                })

            }

        ]
    },
    {
        path: "/world-of-fm",
        generateSeoTags: (data, lang) => {
            return {
                title: 'FM worldwide'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: WorldOfFM,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.freedommusic.rs/locations', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        data
                    }
                })

            }
        ]
    },
    {
        path: "/people-of-fm",
        generateSeoTags: (data, lang) => {
            return {
                title: 'FM tribe'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: PeopleOfFM,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.freedommusic.rs/people-of-fm', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        data,
                        gallery: data.images
                    }
                })

            }
        ]
    },
    {
        path: "/igrice",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Games'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: GamesPage,
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction('https://api.freedommusic.rs/games', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: 0, //query.page
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        page: query.page ? query.page : 0,
                        data: result.items,
                        total: result.total
                    }
                })

            }
        ]

    },

    {
        path: "/igrice/rezultati/svi",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Results'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: ResultsPage,
        loadData: [

            (fetchFunction, lang, match) => {
                return fetchFunction(`https://api.freedommusic.rs/games/all/results`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: null })

                }).then(res => res.json()).then((result) => {

                    return {
                        gamesResults: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://api.freedommusic.rs/games/results/settings`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        settings: result,
                    }
                })

            }
        ]
    },
    {
        path: "/igrice/rezultati/bodovi",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Results'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: ResultsPointsPage,
        loadData: [

            (fetchFunction, lang, match, alias) => {
                return fetchFunction(`https://api.freedommusic.rs/games/all/results`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: null })

                }).then(res => res.json()).then((result) => {

                    return {
                        gamesResults: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://api.freedommusic.rs/games/results/settings`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        settings: result,
                    }
                })

            }
        ]
    },
    {
        path: "/igrice/rezultati/:alias",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Results'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: ResultsPointsPage,
        loadData: [

            (fetchFunction, lang, match, alias) => {
                return fetchFunction(`https://api.freedommusic.rs/games/all/results`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: alias })

                }).then(res => res.json()).then((result) => {

                    return {
                        gamesResults: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://api.freedommusic.rs/games/results/settings`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        settings: result,
                    }
                })

            }
        ]
    },

    {
        path: "/igrice/:alias/play",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Games'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: PlayGamePage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://api.freedommusic.rs/games/${lang}/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://api.freedommusic.rs/games/all/results`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: null })

                }).then(res => res.json()).then((result) => {

                    return {
                        gamesResults: result,
                    }
                })

            }
        ]
    },

    {
        path: "/igrice/:alias",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Games'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: GamesDetailPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://api.freedommusic.rs/games/${lang}/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://api.freedommusic.rs/games/all/results`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: null })

                }).then(res => res.json()).then((result) => {

                    return {
                        gamesResults: result,
                    }
                })

            }
        ]
    },

    {
        path: "/o-nama",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Abour us'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: AboutUsPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.freedommusic.rs/aboutus', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        data
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.freedommusic.rs/blog/latest', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        latestBlog: result,
                    }
                })

            }



        ]

    },
    {
        path: "/novosti",
        generateSeoTags: (data, lang) => {
            return {
                title: 'News'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: BlogPage,
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction('https://api.freedommusic.rs/blog', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: 0, //query.page
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {
                    return {
                        page: query.page ? query.page : 0,
                        items: result.items,
                        total: result.total
                    }
                })

            }
        ]


    },

    {
        path: "/pocetna/:alias",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Latest news'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: HomeDetailPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.freedommusic.rs/blog/latest', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        latestBlog: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://api.freedommusic.rs/home/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            }




        ]

    },
    {
        path: "/novosti/:alias",
        generateSeoTags: (data, lang) => {
            return {
                title: 'News'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: BlogDetailPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.freedommusic.rs/blog/latest', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        latestBlog: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://api.freedommusic.rs/blog/detail/${lang}/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            }




        ]

    },


    {
        path: "/usluge/:alias",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Usluge'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: Service1Page,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://api.freedommusic.rs/services/1/${lang}/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            },

            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.freedommusic.rs/services/1', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        pages: result,
                    }
                })

            }

        ]
    },
    {
        path: "/servis/:alias",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Servis'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: ServicePage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://api.freedommusic.rs/services/0/${lang}/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            },

            (fetchFunction, lang, match) => {
                return fetchFunction('https://api.freedommusic.rs/services/0', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        pages: result,
                    }
                })

            }

        ]
    },

    {
        path: "/kontakt",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Contact'.translate(lang) + ' - Freedommusic'.translate(lang),
                description: 'www.freedommusic.rs'.translate(lang),
                'og:image': 'https://freedommusic.rs/freedommusic-logo.png'
            }
        },
        component: ContactPage,
        loadData: []

    },
];

export function generateRoutes(r) {
    let newRoutes = [];
    for (let i = 0; i < r.length; i++) {
        newRoutes.push(r[i]);

        newRoutes.push({
            ...r[i],
            path: linkTranslates.en[r[i].path]
        })
    }
    return newRoutes;
}
