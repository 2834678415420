import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import EventField from '../components/eventField';

import account from '../assets/svg/account-icon.svg';
import account_image from '../assets/images/account-image.png';

import menu from '../assets/svg/menu-icon.svg';
import right_chevron from '../assets/svg/right-chevron.svg';
import red_right_chevron from '../assets/svg/red-right-chevron.svg';
import account_right_chevron from '../assets/svg/account-right-chevron.svg';
import edit from '../assets/svg/edit.svg';
import password from '../assets/svg/password.svg';
import left_chevron from '../assets/svg/left-chevron.svg';
import top_chevron from '../assets/svg/top-chevron.svg';
import search from '../assets/svg/search-icon.svg';
import close from '../assets/svg/close-icon.svg';
import music from '../assets/images/music.jpg';

import Text from '../components/forms/fields/text'
import ChangePasswordForm from '../components/forms/changePasswordForm';
import EditProfileForm from '../components/forms/editProfileForm';



import Map from '../components/map';

import moment from 'moment';



import Slider from "react-slick";
import BlogArticle from '../components/blogArticle';
import Newsletter from '../components/newsletter';
import ReactPaginate from 'react-paginate';
import ReactGA from 'react-ga';

var striptags = require('striptags');

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);

        this.state = {
            promotedProjects: [],
            ...props.initialData,
            page: 0,
            activeIndex: 0
        };
    }
    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        //console.log(search);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
        if (prevProps.page != this.props.page) {
            if (this.props.page >= this.state.total / 9)
                return;

            this.updateParam('page', this.props.page);
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        this.updateStateFromSearch(this.get);


        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }

    get() {
        fetch('https://api.freedommusic.rs/performers', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: this.state.page,
                lang: this.props.lang
            })
        }).then(res => res.json()).then((result) => {
            console.log(result);
            this.setState({
                performers: result.items,
                total: result.total
            })
        })

    }



    render() {




        return (
            <div className="page-wrap">
                {/* {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null

                } */}

                <section>
                    <Container fluid>
                        <Row className="home-content">

                            {/* {
                                this.state.performers && this.state.performers.map((item, idx) => {
                                    return (
                                        <Col lg="4">
                                            <div className="event-field">
                                                <Link lang={this.props.lang} to={`/performer/${Object.translate(item, 'alias', this.props.lang)}`}>

                                                    <div className="home-field overlay field-info-overlay">
                                                        <img src={Object.get(item, 'image')} />
                                                        <div className="field-preview field-preview-overlay">
                                                            <h6>{Object.translate(item, 'name', this.props.lang)} </h6>
                                                            <Isvg src={top_chevron} />
                                                        </div>
                                                        <div className="field-info">
                                                            <div className="info-text">
                                                                <h3>{Object.translate(item, 'name', this.props.lang)}</h3>
                                                                <p>{Object.translate(item, 'description', this.props.lang)}</p>
                                                            </div>
                                                            <button className="clean-btn"><Isvg src={red_right_chevron} /></button>
                                                        </div>
                                                    </div>

                                                </Link>
                                            </div>
                                        </Col>
                                    )
                                })
                            } */}
                            {
                                this.state.data && this.state.data.map((item, idx) => {
                                    return (
                                        <>
                                            {
                                                idx == 0 ?
                                                    <Col lg="12">
                                                        <div className="home-news-field">
                                                            <Link lang={this.props.lang} to={`/pocetna/${Object.translate(item, 'alias', this.props.lang)}`}>

                                                            <div className="home-field overlay field-info-overlay">
                                                                <img src={Object.get(item, 'image')} />
                                                                {/* <div className="field-preview field-preview-overlay">
                                                                        <h6>{Object.translate(item, 'title', this.props.lang)} </h6>
                                                                        <Isvg src={top_chevron} />
                                                                    </div> */}
                                                                <div className="field-info">
                                                                    <div className="info-text">
                                                                        <h3>{Object.translate(item, 'title', this.props.lang)}</h3>
                                                                        <div className="stream-player" dangerouslySetInnerHTML={{ __html: Object.translate(item, 'shortDescription', this.props.lang) }}>
                                                                        </div>
                                                                        {/* <p>{Object.translate(item, 'shortDescription', this.props.lang)}</p> */}
                                                                        
                                                                    </div>
                                                                    {/* <div className="news-more">
                                                                        <button className="clean-btn"><Isvg src={red_right_chevron} /></button>
                                                                        <h4>CLICK FOR MORE</h4>
                                                                    </div> */}
                                                                </div>
                                                            </div>

                                                            </Link>
                                                        </div>
                                                    </Col>
                                                    :
                                                    idx == 1 || idx == 2 || idx == 3 ?
                                                        <Col lg="4">
                                                            <div className="home-event-field">
                                                                <Link lang={this.props.lang} to={`/pocetna/${Object.translate(item, 'alias', this.props.lang)}`}>

                                                                <div className="home-event-field overlay field-info-overlay">
                                                                    <img src={Object.get(item, 'image')} />
                                                                    <div className="field-preview field-preview-overlay">
                                                                        <h6>{Object.translate(item, 'title', this.props.lang)} </h6>
                                                                        <Isvg src={top_chevron} />
                                                                    </div>
                                                                    <div className="field-info">
                                                                        <div className="info-text">
                                                                            <h3>{Object.translate(item, 'title', this.props.lang)}</h3>
                                                                            <div className="content" dangerouslySetInnerHTML={{ __html: Object.translate(item, 'shortDescription', this.props.lang) }}></div>
                                                                        </div>
                                                                        <button className="clean-btn"><Isvg src={red_right_chevron} /></button>
                                                                    </div>

                                                                </div>

                                                                </Link>
                                                            </div>
                                                        </Col>
                                                        : null
                                            }
                                        </>

                                    )
                                })
                            }

                        </Row>

                    </Container>
                </section>
            </div >
        );
    }
}

export default Page(HomePage);