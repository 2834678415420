import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import moment from 'moment';
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';


import performer from '../assets/images/performer.png';
import video from '../assets/images/video.png';
import preporuka from '../assets/images/preporuka.png';
import timelinePoint from '../assets/svg/timeline-point.svg';

import music from '../assets/images/music.jpg';


import red_right_chevron from '../assets/svg/red-right-chevron.svg';
import top_chevron from '../assets/svg/top-chevron.svg';
import calendar from '../assets/svg/calendar-blog-detail.svg';
import spotify from '../assets/svg/blog-detail1.svg';
import instagram from '../assets/svg/blog-detail2.svg';
import facebook from '../assets/svg/blog-detail3.svg';

import EventField from '../components/eventField';


import Slider from "react-slick";
import BlogArticle from '../components/blogArticle';
import Newsletter from '../components/newsletter';
import ReactPaginate from 'react-paginate';

import ReactGA from 'react-ga';

var striptags = require('striptags');




class CalendarDetailPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            activeIndex: 0,

        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);

    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }


    }



    render() {

        return (
            <div className="page-wrap">
                {/* {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null

                } */}
                <section>
                    <Container fluid className="mob-padding">
                        <Row className="calendar-timeline">
                            <Col lg="12">
                                <div className="timeline">
                                    <div className="line">
                                        <hr />
                                        {
                                            this.state.data ?
                                                <div className="months">
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.data.month == 1 ? "active-month clean-btn " : "month-btn clean-btn"} ><div className="button-field"><Isvg src={timelinePoint} />JANUAR <p>{this.state.data.year}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.data.month == 2 ? "active-month clean-btn " : "month-btn clean-btn"} ><div className="button-field"><Isvg src={timelinePoint} />FEBRUAR<p>{this.state.data.year}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.data.month == 3 ? "active-month clean-btn " : "month-btn clean-btn"} ><div className="button-field"><Isvg src={timelinePoint} />MART<p>{this.state.data.year}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.data.month == 4 ? "active-month clean-btn " : "month-btn clean-btn"} ><div className="button-field"><Isvg src={timelinePoint} />APRIL<p>{this.state.data.year}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.data.month == 5 ? "active-month clean-btn " : "month-btn clean-btn"} ><div className="button-field"><Isvg src={timelinePoint} />MAJ<p>{this.state.data.year}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.data.month == 6 ? "active-month clean-btn " : "month-btn clean-btn"} ><div className="button-field"><Isvg src={timelinePoint} />JUN<p>{this.state.data.year}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.data.month == 7 ? "active-month clean-btn " : "month-btn clean-btn"} ><div className="button-field"><Isvg src={timelinePoint} />JUL<p>{this.state.data.year}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.data.month == 8 ? "active-month clean-btn " : "month-btn clean-btn"} ><div className="button-field"><Isvg src={timelinePoint} />AVGUST<p>{this.state.data.year}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.data.month == 9 ? "active-month clean-btn " : "month-btn clean-btn"} ><div className="button-field"><Isvg src={timelinePoint} />SEPTEMBAR<p>{this.state.data.year}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.data.month == 10 ? "active-month clean-btn " : "month-btn clean-btn"} ><div className="button-field"><Isvg src={timelinePoint} />OKTOBAR<p>{this.state.data.year}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.data.month == 11 ? "active-month clean-btn " : "month-btn clean-btn"} ><div className="button-field"><Isvg src={timelinePoint} />NOVEMBAR<p>{this.state.data.year}</p></div></button>
                                                    </Col>
                                                    <Col lg="1" className="no-padding-col">
                                                        <button className={this.state.data.month == 12 ? "active-month clean-btn " : "month-btn clean-btn"} ><div className="button-field"><Isvg src={timelinePoint} />DECEMBAR<p>{this.state.data.year}</p></div></button>
                                                    </Col>
                                                </div>
                                                :
                                                null
                                        }

                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="performer">
                            <Col lg="12">
                                {
                                    this.state.data ?
                                        <>
                                            <div className="performer-image overlay overlay2">
                                                <img src={Object.get(this.state.data, 'image')} />
                                            </div>
                                            <div className="performer-info">
                                                <h1 className="marginb42">{Object.translate(this.state.data, 'title', this.props.lang)} <br />
                                                    <span>{moment.unix(this.state.data.date && this.state.data.date).format('DD.MM.YYYY')}</span>
                                                </h1>

                                                {
                                                    this.state.data && this.state.data.content && this.state.data.content.map((item, idx) => {
                                                        if (item.type == 'image') {
                                                            return (
                                                                <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section no-padding-col">
                                                                    <img src={item.value} />
                                                                </Col>
                                                            )
                                                        } else {
                                                            return (
                                                                <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section no-padding-col marginb55" dangerouslySetInnerHTML={{ __html: item.value && item.value[this.props.lang] }}>
                                                                </Col>
                                                            )
                                                        }
                                                    })
                                                }
                                                {/* <Col lg="12" className="no-padding-col">
                                                    <div className="calendar-detail">
                                                        <h2>Nastupaju</h2>
                                                        <Link to="/galerija"><button className="button-gallery">Galerija slika</button></Link>
                                                    </div>
                                                    
                                                </Col> */}


                                                <div className="performer-social-networks">
                                                    {
                                                        Object.translate(this.state.data, 'buttonLink1', this.props.lang) ?
                                                        <a href={Object.translate(this.state.data, 'buttonLink1', this.props.lang)} target="_blank"><Isvg src={instagram} /></a> : null
                                                    }
                                                    {
                                                        Object.translate(this.state.data, 'buttonLink2', this.props.lang) ?
                                                        <a href={Object.translate(this.state.data, 'buttonLink2', this.props.lang)} target="_blank"><Isvg src={facebook} /></a> : null
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                }

                            </Col>
                        </Row>
                        <Row className="event-performers">
                            <Col lg="12" className="mob-padding">
                                <div className="calendar-detail">
                                    <h2>Nastupaju</h2>
                                    {
                                        this.state.data && this.state.data.galleryAlias ?
                                            <Link lang={this.props.lang} to={`/galerija/${Object.translate(this.state.data, 'galleryAlias', this.props.lang)}`}>
                                                <button className="button-gallery">Galerija slika</button>
                                            </Link>
                                            :
                                            null
                                    }

                                </div>

                            </Col>

                            {
                                this.state.performers && this.state.performers.map((item, idx) => {
                                    return (
                                        <Col lg="4" className="mob-padding">
                                            <div className="event-field">
                                                <Link lang={this.props.lang} to={`/performer/${Object.translate(item, 'alias', this.props.lang)}`}>
                                                    <div className="home-field overlay field-info-overlay">
                                                        <img src={Object.get(item, 'image')} />
                                                        <div className="field-preview field-preview-overlay">
                                                            <h6>{Object.translate(item, 'name', this.props.lang)}</h6>
                                                            <Isvg src={top_chevron} />
                                                        </div>
                                                        <div className="field-info">
                                                            <div className="info-text">
                                                                <h3>{Object.translate(item, 'name', this.props.lang)}</h3>
                                                                <p>{Object.translate(item, 'description', this.props.lang)}</p>
                                                            </div>

                                                            <button className="clean-btn"><Isvg src={red_right_chevron} /></button>
                                                        </div>

                                                    </div>
                                                </Link>
                                            </div>

                                        </Col>
                                    )
                                })
                            }
                        </Row>

                    </Container>
                </section>
            </div>
        );
    }
}

export default Page(CalendarDetailPage);