import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import music from '../assets/images/music.jpg';
import close_ico from '../assets/svg/close-ico.svg';
import lightbox_dots from '../assets/svg/lightbox-dots.svg';

import ReactGA from 'react-ga';

import spotify from '../assets/svg/blog-detail1.svg';
import instagram from '../assets/svg/blog-detail2.svg';
import facebook from '../assets/svg/blog-detail3.svg';




import menu from '../assets/svg/menu-icon.svg';
import right_chevron from '../assets/svg/right-chevron.svg';
import red_right_chevron from '../assets/svg/red-right-chevron.svg';



import Map from '../components/map';

import moment from 'moment';



import Slider from "react-slick";
import BlogArticle from '../components/blogArticle';
import Newsletter from '../components/newsletter';

var striptags = require('striptags');

class PeopleOfFM extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.state = {
            promotedProjects: [],
            ...props.initialData,
            activePage: 0,
            activeIndex: 0,
            gallery: []
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);


    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.gallery.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.gallery.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }





    render() {


        const { activeIndex } = this.state;
        const slides = this.state.gallery && this.state.gallery.map((item) => {
            return (
                <CarouselItem
                    tag="div"
                    key={item}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                >
                    <div className="lightbox-item">
                        <div className="lightbox-item-title">
                            <h6>.</h6>
                            <div className="close" onClick={() => this.setState({ lightbox: null })}>
                                <Isvg src={close_ico} />
                            </div>
                        </div>
                        <img src={item} />
                    </div>

                </CarouselItem>
            );
        });

        return (
            <div className="page-wrap">

                {/* {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null

                } */}
                <section>
                    <Container fluid>
                        <Row className="stream-content">
                            <Col lg="12">
                                <div className="stram-into">
                                    <h1>Freedom music Tribe</h1>
                                    {
                                        this.state.data ?
                                            <p>{Object.translate(this.state.data, 'description', this.props.lang)}</p> : null
                                    }

                                </div>
                                <div className="people-of-fm">
                                    {
                                        this.state.gallery && this.state.gallery.map((item, idx) => {
                                            return (
                                                <div className="gallery-field" onClick={() => {
                                                    let index = idx;
                                                    this.setState({ lightbox: true, activeIndex: index })
                                                }}>
                                                    <div className="active">
                                                        <img src={item} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>

                            </Col>
                        </Row>

                    </Container>
                </section>
                {this.state.lightbox ?
                    <div className="lightbox2">

                        <Carousel
                            activeIndex={activeIndex}
                            next={this.next}
                            previous={this.previous}
                            autoPlay={null}
                        >
                            <CarouselIndicators items={slides} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                            {slides}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                        </Carousel>

                    </div>
                    : null
                }
            </div >
        );
    }
}

export default Page(PeopleOfFM);