export const langs = {
    "ba": {
        "Nase usluge": "Naše usluge",
        "Usluge transporta koje nudimo": "Usluge transporta koje nudimo",
        "KOMPANIJA OSNOVANA": "KOMPANIJA OSNOVANA",
        "ZEMALJA POSLUJEMO": "ZEMALJA POSLUJEMO",
        "POŠILJKI DOSTAVLJENO": "POŠILJKI DOSTAVLJENO",
        "Aktuelnosti": "Aktuelnosti",
        "Novosti": "Novosti",
        "i obavještenja": "i obavještenja",
        "Aktuelnosti vezane za našu firmu": "Aktuelnosti vezane za našu firmu",
        "Telefoni:": "Telefoni:",
        "Radno vrijeme:": "Radno vrijeme:",
        "Detaljnije": "Detaljnije",
        "O nama": "O nama",
        "Početna": "Početna",
        "Usluge": "Usluge",
        "Navigacija": "Navigacija",
        "Kontakt": "Kontakt",
        "Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.": "Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.",
        "INFORMACIJE": "INFORMACIJE",
        "Top TLS d.o.o. korisni podaci": "Top TLS d.o.o. korisni podaci",
        "ID broj": "ID broj",
        "PDV broj:": "PDV broj:",
        "Registracija:": "Registracija:",
        "Općinski sud u Sarajevu": "Općinski sud u Sarajevu",
        "Transakcijski račun – UniCredit bank": "Transakcijski račun – UniCredit bank",
        "Devizni račun (IBAN) – UniCredit bank": "Devizni račun (IBAN) – UniCredit bank",
        "Devizni račun (SWIFT):": "Devizni račun (SWIFT):",
        "Ime": "Ime",
        "Prezime": "Prezime",
        "Telefon": "Telefon",
        "E-mail": "E-mail",
        "Naslov": "Naslov",
        "Vaša poruka": "Vaša poruka"
    },
    "en": {
        "Nase usluge": "Our services",
        "Usluge transporta koje nudimo": "Transport services we offer",
        "KOMPANIJA OSNOVANA": "COMPANY ESTABLISHED",
        "ZEMALJA POSLUJEMO": "COUNTRIES WE BUSINESS",
        "POŠILJKI DOSTAVLJENO": "SHIPMENTS DELIVERED",
        "Aktuelnosti": "Current news",
        "Novosti": "News",
        "i obavještenja": "and announcements",
        "Aktuelnosti vezane za našu firmu": "News related to our company",
        "Telefoni:": "Telephones:",
        "Radno vrijeme:": "Working hours:",
        "Detaljnije": "More details",
        "O nama": "About Us",
        "Početna": "Home",
        "Usluge": "Services",
        "Uvoz vozila": "Import of vehicles",
        "Navigacija": "Navigation",
        "Kontakt": "Contact",
        "Vaš upit": "Your inquiry",
        "Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.": "Feel free to contact us. We will answer you as soon as possible.",
        "INFORMACIJE": "INFORMATION",
        "Top TLS d.o.o. korisni podaci": "Top TLS d.o.o. useful data",
        "ID broj": "ID number",
        "PDV broj:": "VAT number:",
        "Registracija:": "Registration:",
        "Općinski sud u Sarajevu": "Municipal Court in Sarajevo",
        "Transakcijski račun – UniCredit bank": "Transaction account - UniCredit bank",
        "Devizni račun (IBAN) – UniCredit bank": "Foreign currency account (IBAN) - UniCredit bank",
        "Devizni račun (SWIFT):": "Foreign currency account (SWIFT):",
        "Ime": "Name",
        "Prezime": "Surname",
        "Telefon": "Phone",
        "E-mail": "E-mail",
        "Naslov": "Title",
        "Vaša poruka": "Your message",
        "POŠALJI": "SEND",
        "Vaša poruka je poslata, očekujte odgovor ubrzo.": "Your message has been sent, expect a reply soon."

    }
}