import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
// import { Link, Redirect } from 'react-router-dom';


import Text from './fields/text';
import Textarea from './fields/textarea';
import rightChevron from '../../assets/svg/right-chevron.svg';
import Isvg from 'react-inlinesvg';
import Link from '../../components/link';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type
}) => (

        <Text
            placeholder={placeholder}
            errorText={touched && error}
            label={label}
            error={touched && error}
            type={type}
            {...input}
        />
    )

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Textarea
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )


class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }



    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;
        console.log(pristine, submitting);

        return (
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="email"
                                component={renderTextField}
                                label={"Email".translate(this.props.lang)}
                                placeholder={"Enter email".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    


                    <Col lg="12" className="btn-wrap">
                        
                        {this.props.loading ?
                            null
                            :
                            <button className="button2">{'SEND'.translate(this.props.lang)}</button>
                        }
                    </Col>
                   

                </Row>


            </form>

        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
