import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';



import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';



import downChevron from '../assets/svg/down-chevron.svg';

import Map from '../components/map';
import Newsletter from '../components/newsletter';

import ContactForm from '../components/forms/contactForm';
import mailIcon from '../assets/svg/contact-page-mail.svg';
import phoneIcon from '../assets/svg/contact-page-phone.svg';
import locationIcon from '../assets/svg/contact-page-location.svg';

import ReactGA from 'react-ga';

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {

        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }

    submit(data) {
        this.setState({
            formLoading: true
        }, () => {

            fetch('https://api.freedommusic.rs/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(
                    data
                )
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    //formLoading: null,
                    formDone: true
                })
            })
        })
    }


    render() {

        return (
            <div>
                <section className="page-into">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>Kontakt</h1>
                            </Col>
                            <Col lg="6">
                                <ul>
                                    <li><Link to='/'>Početna</Link></li>
                                    <li><Link to='/'>Kontakt</Link></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="contact-page-section">
                    <Map {...this.props} />

                    <Container>
                        <Row>
                            <Col lg="4">
                                <div className="contact-item v-space">
                                    <Isvg src={mailIcon} />
                                    <h6>E-mail adrese</h6>
                                    <p><a href="mailto:info@autoprojekt.ba">info@autoprojekt.ba</a></p>
                                    <p><a href="mailto:prodaja@autoprojekt.ba">prodaja@autoprojekt.ba</a></p>
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="contact-item v-space">
                                    <Isvg src={phoneIcon} />
                                    <h6>Kontakt telefoni</h6>
                                    <p><a href="mailto:info@autoprojekt.ba">+387 (0) 61 904 715</a></p>
                                    <p><a href="mailto:prodaja@autoprojekt.ba">+387 (0) 61 904 715</a></p>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="contact-item">
                                    <Isvg src={locationIcon} />
                                    <h6>Adresa</h6>
                                    <p>Vladimira Nazora 5</p>
                                    <p>71000 Sarajevo</p>
                                </div>
                            </Col>

                        </Row>
                    </Container>

                </section>

                <section className="contact-page-form-section">
                <Container>
                    <Row>
                        <Col lg="12">
                            <h6>POŠALJI UPIT</h6>
                            <h3>Kontaktirajte nas</h3>
                            <ContactForm lang={this.props.lang} onSubmit={this.submit} loading={this.state.formLoading}></ContactForm>
                            {this.state.formDone ?
                                        <p className="el_YUkXJVvxfk">{'Vaša poruka je poslata, očekujte odgovor ubrzo.'.translate(this.props.lang)}</p>
                                    :
                                    null
                                }

                        </Col>
                    </Row>
                    </Container>

                </section>

                <Newsletter socketIOClient={this.props.socketIOClient}></Newsletter>

            </div>
        );
    }
}

export default Page(ContactPage);