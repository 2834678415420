import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import moment from 'moment'

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import ReactGA from 'react-ga';
import red_right_chevron from '../assets/svg/red-right-chevron.svg';
import right_chevron from '../assets/svg/right-chevron.svg';
import music from '../assets/images/music.jpg';


import new_image from '../assets/images/news-page-image.png';
import youtube from '../assets/images/youtube.png';

import calendar from '../assets/svg/calendar-blog-detail.svg';
import spotify from '../assets/svg/blog-detail1.svg';
import instagram from '../assets/svg/blog-detail2.svg';
import facebook from '../assets/svg/blog-detail3.svg';

import Slider from "react-slick";
import BlogArticle from '../components/blogArticle';
import Newsletter from '../components/newsletter';
import ReactPaginate from 'react-paginate';

var striptags = require('striptags');



class GalleryPage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);

        this.state = {
            ...props.initialData,
            activeIndex: 0,
            page: 0,
        };
    }
    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        //console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
        if (prevProps.page != this.props.page) {
            if (this.props.page >= this.state.total / 9)
                return;

            this.updateParam('page', this.props.page);
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        this.props.setPage(0);

        this.updateStateFromSearch(this.get);
        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }
    get() {
        fetch('https://api.freedommusic.rs/gallery', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: this.state.page,
                lang: this.props.lang
            })
        }).then(res => res.json()).then((result) => {
            console.log(result);
            this.setState({
                gallery: result.items,
                total: result.total
            })
            this.props.setPageTotal(result.total);
        })
        

    }




    render() {

        return (
            <div className="page-wrap">
                {/* {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null

                } */}
                <section>
                    <Container fluid>
                        <Row className="gallery">
                            {
                                this.state.gallery && this.state.gallery.map((item, idx) => {
                                    return (
                                        <Col lg="4">
                                            <div className="gallery-field-wrap">
                                                <Link to={`/galerija/${Object.translate(item, 'alias', this.props.lang)}`}>

                                                    <div className="gallery-field overlay active">
                                                        <img src={Object.get(item, 'images[0]')} />
                                                        <div className="field-info">
                                                            <h3>{Object.translate(item, 'name', this.props.lang)}</h3>
                                                            {/* <h3>{moment.unix(item.date).format('DD.MM.YYYY')}</h3> */}
                                                        </div>
                                                        <button className="field-preview">
                                                            <h6>Click for more</h6>
                                                            <Isvg src={right_chevron} />
                                                        </button>
                                                        <div className="active-button">
                                                            <div className="click-for-more">
                                                                <button className="clean-btn"><Isvg src={red_right_chevron} /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>


                                        </Col>
                                    )
                                })
                            }

                        </Row>
                        
                    </Container>
                </section>
            </div >
        );
    }
}

export default Page(GalleryPage);