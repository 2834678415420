import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import Iframe from 'react-iframe';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import facebook_icon from '../assets/svg/social-facebook.svg';
import instagram_icon from '../assets/svg/social-instagram.svg';
import facebook_page from '../assets/images/facebook-page.png';
import instagram_page from '../assets/images/instagram-page.png';
import ReactGA from 'react-ga';


import spotify from '../assets/svg/blog-detail1.svg';
import instagram from '../assets/svg/blog-detail2.svg';
import facebook from '../assets/svg/blog-detail3.svg';
import play from '../assets/svg/play-stream.svg';
import stream_image from '../assets/images/stream-img.png';


import EventField from '../components/eventField';

import account from '../assets/svg/account-icon.svg';
import account_image from '../assets/images/account-image.png';

import menu from '../assets/svg/menu-icon.svg';
import right_chevron from '../assets/svg/right-chevron.svg';
import red_right_chevron from '../assets/svg/red-right-chevron.svg';
import account_right_chevron from '../assets/svg/account-right-chevron.svg';
import edit from '../assets/svg/edit.svg';
import password from '../assets/svg/password.svg';
import left_chevron from '../assets/svg/left-chevron.svg';
import top_chevron from '../assets/svg/top-chevron.svg';
import search from '../assets/svg/search-icon.svg';
import close from '../assets/svg/close-icon.svg';
import music from '../assets/images/music.jpg';

import Text from '../components/forms/fields/text'
import ChangePasswordForm from '../components/forms/changePasswordForm';
import EditProfileForm from '../components/forms/editProfileForm';



import Map from '../components/map';

import moment from 'moment';



import Slider from "react-slick";
import BlogArticle from '../components/blogArticle';
import Newsletter from '../components/newsletter';

var striptags = require('striptags');

class StreamPage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.state = {
            promotedProjects: [],
            ...props.initialData,
            activePage: 1
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        
        //ReactGA.initialize('G-TXSX33V4LW');
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }


    submit(data) {
        // this.setState({
        //     formLoading: true
        // }, () => {

        //     fetch('https://api.freedommusic.rs/contact', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',

        //         },
        //         body: JSON.stringify(
        //             data
        //         )
        //     }).then((res) => res.json()).then((result) => {
        //         this.setState({
        //             //formLoading: null,
        //             formDone: true
        //         })
        //     })
        // })
    }



    render() {


        console.log(Object.get(this.state, 'section[0].image'))
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: typeof window !== 'undefined' && window.innerWidth < 768 ? 1 : 3,
            slidesToScroll: 1
        };

        return (
            <div className="page-wrap">
                {/* {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null

                } */}

                <section>
                    <Container fluid>
                        {/*<Row className="social-media-content">
                            <Col lg="12">
                                <div className="social-page-wrap">
                                    <div className={this.state.activePage && this.state.activePage == 1 ? "social-network-page" : "social-network-page-not-active"}>
                                        <div className="facebook-field-wrap" ref={node => this.fbcontainter = node}>
                                            <Iframe src={`https://www.facebook.com/plugins/likebox.php?href=http%3A%2F%2Fwww.facebook.com%2Ffreedommusicsrbija&width=${this.fbcontainter && this.fbcontainter.clientWidth}&colorscheme=light&show_faces=true&border_color&stream=true&header=true&height=${this.fbcontainter && this.fbcontainter.clientHeight}`}
                                                id="myId"
                                                className="iframe-social"
                                                allow="fullscreen"
                                            />
                                        </div>
                                        <div className="facebook-button-wrap">
                                            <button className="facebook-page-button" onClick={() => { this.setState({ activePage: 1 }) }}><Isvg src={facebook_icon} />FACEBOOK</button>
                                        </div>
                                    </div>
                                    <div className={this.state.activePage && this.state.activePage == 2 ? "social-network-page" : "social-network-page-not-active"}>
                                        <div className="facebook-field-wrap">
                                            {
                                                this.props.instagramPhotos && this.props.instagramPhotos.map((item, idx) => {
                                                    return (
                                                        <a href={item.link}>
                                                            <div className="insta-image">
                                                                <img src={item.preview} />
                                                            </div>

                                                        </a>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="instagram-button-wrap">
                                            <button className="facebook-page-button" onClick={() => { this.setState({ activePage: 2 }) }}><Isvg src={instagram_icon} />INSTAGRAM</button>
                                        </div>
                                    </div>
                                    <div className="social-page-buttons-wrap">
                                        <div className="social-page-buttons">
                                            <div className="facebook-button-wrap-mob">
                                                <button className={this.state.activePage && this.state.activePage == 1 ? "facebook-page-button-mob" : "facebook-page-button-mob-not-active"} onClick={() => { this.setState({ activePage: 1 }) }}><Isvg src={facebook_icon} />FACEBOOK</button>
                                            </div>
                                            <div className="instagram-button-wrap-mob">
                                                <button className={this.state.activePage && this.state.activePage == 2 ? "facebook-page-button-mob" : "facebook-page-button-mob-not-active"} onClick={() => { this.setState({ activePage: 2 }) }}><Isvg src={instagram_icon} />INSTAGRAM</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                                        </Row>*/}
                        <Row className="video-gallery">
                            {
                                this.state.data && this.state.data.content && this.state.data.content.map((item, idx) => {
                                    if (item.type == 'image') {
                                        return (
                                            <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section">
                                                <img src={item.value} />
                                            </Col>
                                        )
                                    } else {
                                        return (
                                            <Col lg={{ size: item.width.desktop }} xs={{ size: item.width.mobile }} className="block-section" dangerouslySetInnerHTML={{ __html: item.value && item.value[this.props.lang] }}>
                                            </Col>
                                        )
                                    }
                                })
                            }

                        </Row>
                    </Container>
                </section>
            </div >
        );
    }
}

export default Page(StreamPage);